import React from 'react'
import { StepListener } from '../../interface/stepsInterfaces'
import { CriticalUIObject, UIState } from '../../interface/criticalInterface'
import { AxiosResponse } from 'axios'
import { handleError } from '../../utils/handleError'
import { ErrorFamily } from '../../assets/error/ErrorMap'
import { Executor } from '../../executor/Executor'
import { Logger } from '../../../commons/utils/helperMethods'

export const requestCriticalFwListener = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepListener => {
  return {
    onStart: (): Promise<void> => {
      Logger.debug('requestCriticalFwListener.OnStart')
      setUIObject({ ...uiObject, uiState: UIState.startCriticalUpdateScreen })
      return Promise.resolve()
    },
    onFinished: (payload: object, executor: Executor): Promise<void> => {
      Logger.debug('requestCriticalFwListener.OnFinished', payload)
      const response = payload as AxiosResponse
      if (response.status === 200) {
        executor.executeNextStep()
      } else {
        handleError(
          uiObject,
          setUIObject,
          new Error('Request failed'),
          executor,
          `${response.status}`,
          ErrorFamily.BackendCommunication
        )
      }
      return Promise.resolve()
    },
    onError: (exception: Error, executor: Executor): Promise<void> => {
      Logger.debug('requestCriticalFwListener.OnError', exception)
      handleError(
        uiObject,
        setUIObject,
        exception,
        executor,
        exception.message,
        ErrorFamily.BackendCommunication
      )
      return Promise.resolve()
    }
  }
}
