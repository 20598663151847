import { Executor } from '../executor/Executor'
import Action from './action'

export const SHELL_PROPS = 'shell_props'

export abstract class StepsCoordinator {
  steps: Step[]
  executedSteps: string[] = []
  currentStep: Step = null
  constructor(steps: Step[]) {
    this.steps = steps
  }

  public abstract getNextStep(): Step
  public abstract hasSteps(): boolean

  public next(): Step {
    this.currentStep = this.getNextStep()
    this.executedSteps.push(this.currentStep.stepName)
    return this.currentStep
  }

  public reset() {
    this.currentStep = null
    this.executedSteps = []
    this.steps.forEach((step) => (step.result = null))
  }

  public getResultOf(stepName): object {
    return this.steps.find((step) => step.stepName === stepName)?.result
  }
}

export abstract class Step {
  stepName: string
  configuration: StepConfiguration
  action: Action
  result: object

  constructor(
    stepName: string,
    configuration: StepConfiguration,
    action: Action
  ) {
    this.stepName = stepName
    this.configuration = configuration
    this.action = action
  }

  public async run(results: Map<string, object>): Promise<object> {
    const input = this.configuration.input(results)
    this.result = await this.action.execute(input)
    return this.result
  }
}

export interface StepConfiguration {
  input: (stepsResults: Map<string, object>) => object
  listener: StepListener
}

export interface StepListener {
  onStart: () => Promise<void>
  onFinished: (payload: object, executor: Executor) => Promise<void>
  onError: (exception: Error, executor: Executor) => Promise<void>
}
