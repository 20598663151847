/* eslint-disable react-hooks/rules-of-hooks */
import { StepConfiguration } from '../../interface/stepsInterfaces'
import { CriticalStepId } from '../../coordinator/criticalStepCoordinator'
import { ServiceRoutingPlugin } from '@jarvis/jweb-core'
import { CriticalUIObject } from '../../interface/criticalInterface'
import { getServiceRoutingLaunchOptionsListener } from './listener'

export const getServRoutingOptsStepConfiguration = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepConfiguration => {
  return {
    input: (results: Map<string, object>): object => {
      return results.get(CriticalStepId.WhenJWebReady.toString())['Plugins']
        .ServiceRouting as ServiceRoutingPlugin
    },
    listener: getServiceRoutingLaunchOptionsListener(uiObject, setUIObject)
  }
}
