import { DeviceType } from '../../..'
import { Logger } from '../../../commons/utils/helperMethods'
import { getDeviceTypeById } from '../../../commons/controller/device-discovery-tree'
import Action from '../../interface/action'

export const getDeviceTree = async (
  sessionId: string
): Promise<GetDeviceTypeResult> => {
  Logger.log('getDeviceTree', sessionId)
  return await getDeviceTypeById(sessionId, sessionId)
}

export interface GetDeviceTypeResult {
  deviceId: string | object
  type: DeviceType
}

interface GetDeviceIdInput {
  sessionId: string
}

export class GetDeviceTreeAction extends Action {
  execute(input: GetDeviceIdInput): Promise<object> {
    const result = getDeviceTree(input.sessionId)
    return Promise.resolve(result)
  }
}
