/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import {
  BodyContainer,
  ButtonsContainer,
  Container,
  InformationContainer,
  Title
} from './styles'
import { useAssetsProvider } from '../../context/Language'
import { Button, IconMinusCircle } from '@veneer/core'
import {
  createSupportLink,
  getSupportURL
} from '../../utils/errorHandlerHelpers'
import {
  createSimpleUiEvent,
  CustomDataSimpleUiEvent,
  sendSimpleUiEvent
} from '../../../firmwareUpdateControllerCritical/jarvis/dataCollection'
import { Action } from '@jarvis/jweb-core'

interface CriticalUpdateIncompleteProps {
  isFirstTime: boolean
  onClickRetryButton: () => void
  onClickExitSetup: () => void
  errorCode?: string
}

// Simpleui event parameters
const screenName = 'CriticalUpdateIncomplete'

const openScreen: CustomDataSimpleUiEvent = {
  action: Action?.screenDisplayed,
  screenName: screenName
}

const updatePrinterButton: CustomDataSimpleUiEvent = {
  action: Action?.controlButtonClicked,
  screenName: screenName,
  controlName: 'UpdatePrinter',
  screenMode: 'FirstTime'
}

const exitButton: CustomDataSimpleUiEvent = {
  action: Action?.controlButtonClicked,
  screenName: screenName,
  controlName: 'ExitSetupButton',
  screenMode: 'NotFirstTime'
}

const supportLink: CustomDataSimpleUiEvent = {
  action: Action?.controlHyperLinkClicked,
  screenName: screenName,
  controlName: 'GoToHpSupportWebsite',
  screenMode: 'NotFirstTime'
}

export const CriticalUpdateIncomplete: React.FC<
  CriticalUpdateIncompleteProps
> = ({
  isFirstTime = false,
  onClickRetryButton,
  onClickExitSetup,
  errorCode
}) => {
  const assetsProvider = useAssetsProvider()
  const [titleName, setTitleName] = useState('')
  const [information_container_one, setInformationContainerOne] = useState('')
  const [actionButton, setActionButton] = useState([])
  const containerTwoElementRef = useRef<HTMLParagraphElement>()

  useEffect(() => {
    if (isFirstTime) {
      openScreen.screenMode = 'FirstTime'
      sendSimpleUiEvent(createSimpleUiEvent(openScreen))
    } else {
      openScreen.screenMode = 'NotFirstTime'
      sendSimpleUiEvent(createSimpleUiEvent(openScreen))
    }
  }, [])

  const handleRetryButton = () => {
    onClickRetryButton()
    sendSimpleUiEvent(createSimpleUiEvent(updatePrinterButton))
  }

  const handleExitButton = () => {
    onClickExitSetup()
    sendSimpleUiEvent(createSimpleUiEvent(exitButton))
  }

  const handlerSupportLink = (e) => {
    const el = e.target

    if (el && el.tagName === 'A') {
      sendSimpleUiEvent(createSimpleUiEvent(supportLink))
    }
  }

  const hpSupportLink = createSupportLink(
    assetsProvider.getText(
      'critical_update_incomplete.unable_complete_update.hp_support_link_label'
    ),
    getSupportURL()
  )

  const unableCompleteUpdateInformationContainerTwo = assetsProvider.getText(
    'critical_update_incomplete.unable_complete_update.information_container_two',
    { hpSupportLink }
  )

  useEffect(() => {
    setTitleName(
      assetsProvider.getText(
        isFirstTime
          ? 'critical_update_incomplete.printer_update_incomplete.title'
          : 'critical_update_incomplete.unable_complete_update.title'
      )
    )
    setInformationContainerOne(
      assetsProvider.getText(
        isFirstTime
          ? 'critical_update_incomplete.printer_update_incomplete.information_container_one'
          : 'critical_update_incomplete.unable_complete_update.information_container_one'
      )
    )
    if (isFirstTime) {
      setActionButton([
        <>
          <Button
            appearance="secondary"
            onClick={() => {
              handleRetryButton()
            }}
          >
            {assetsProvider.getText('commons.update_printer')}
          </Button>
        </>
      ])
      containerTwoElementRef.current.innerHTML = assetsProvider.getText(
        'critical_update_incomplete.printer_update_incomplete.information_container_two'
      )
    } else {
      setActionButton([
        <>
          <Button
            appearance="secondary"
            onClick={() => {
              handleExitButton()
            }}
          >
            {assetsProvider.getText('commons.exit_setup')}
          </Button>
        </>
      ])
      containerTwoElementRef.current.innerHTML =
        unableCompleteUpdateInformationContainerTwo
    }
  }, [isFirstTime])

  return (
    <Container>
      <BodyContainer>
        <IconMinusCircle id="icon" color="colorRed6" />
        <Title>{titleName}</Title>
        <InformationContainer>
          <p className="body">{information_container_one}</p>
          <p
            onClick={handlerSupportLink}
            className="body"
            ref={containerTwoElementRef}
          ></p>
          {errorCode ? <p className="errorCode">({errorCode})</p> : null}
        </InformationContainer>
      </BodyContainer>
      <ButtonsContainer>{actionButton}</ButtonsContainer>
    </Container>
  )
}
