import { AxiosResponse } from 'axios'
import {
  ErrorProgressType,
  UpdateProgressResult,
  UpdateResult,
  getDeviceUpdateProgress
} from '../../controller/update-progress-controller'
import { Logger, parseJson, sleep } from '../../../commons/utils/helperMethods'
import Action from '../../interface/action'
import {
  hasUpdate,
  isSuccessLastResult,
  isSuccessStatus,
  printerUpdateCheck,
  PrinterUpdateCheckInput
} from '../printerUpdateCheck/action'
import { HttpResponse } from '../../../commons/plugin/device-http-proxy'
import { HttpMethodType } from '../../../commons/interface/controllerInterface'
import { DeviceType } from '../../../commons/clients/stageapi/firmware-update-client'
import { PayloadTypeUpdateCheck } from '../printerUpdateCheck/listener'

enum CheckUpdateResultType {
  HAS_UPDATE,
  NO_UPDATE,
  ERROR
}

interface CheckUpdateResult {
  resultType: CheckUpdateResultType
  httpResponse?: HttpResponse
}

interface UpdateProgressInput {
  sessionId: string
  interrogation: AxiosResponse
  retriesNumber: number
  deviceType: DeviceType
}

export const getUpdateProgress = async (
  input: UpdateProgressInput
): Promise<object> => {
  Logger.log('getUpdateProgress input', input)

  let result
  const updateProgressInput = input

  if (updateProgressInput.deviceType == DeviceType.CDM) {
    const checkUpdateResult = await checkUpdate(updateProgressInput.sessionId)

    if (checkUpdateResult.resultType == CheckUpdateResultType.HAS_UPDATE) {
      result = await getDeviceUpdateProgress(
        input.sessionId,
        input.interrogation
      )
    } else {
      result = createResult(checkUpdateResult)
    }
  } else {
    result = await getDeviceUpdateProgress(input.sessionId, input.interrogation)
  }

  return { response: result, retriesNumber: input.retriesNumber }
}

export class UpdateProgressAction extends Action {
  execute(input: object): Promise<object> {
    const result = getUpdateProgress(input as UpdateProgressInput)
    return Promise.resolve(result)
  }
}

const timePulling = 5000
const maxRetries = 10

const checkUpdate = async (
  sessionId: string,
  isPatchRequest = true,
  retries = 0
): Promise<CheckUpdateResult> => {
  const payload: PrinterUpdateCheckInput = {
    sessionId: sessionId,
    isPatchRequest
  }

  Logger.debug('Calling checkUpdate function in UpdateProgressAction', payload)

  if (retries > maxRetries) {
    return {
      resultType: CheckUpdateResultType.ERROR
    }
  }

  const resultUpdateCheck = (await printerUpdateCheck(
    payload
  )) as PayloadTypeUpdateCheck
  const checkResponse = resultUpdateCheck.response as HttpResponse

  if (!isSuccessStatus(checkResponse.statusCode)) {
    // error, could not check for update
    return {
      resultType: CheckUpdateResultType.ERROR,
      httpResponse: checkResponse
    }
  }

  if (checkResponse.method == HttpMethodType.PATCH) {
    await sleep(timePulling)
    return await checkUpdate(sessionId, false, ++retries)
  }

  const data = parseJson(checkResponse)
  if (data.state == 'processing') {
    await sleep(timePulling)
    return await checkUpdate(sessionId, false, ++retries)
  }

  if (data.state != 'idle') {
    return {
      resultType: CheckUpdateResultType.ERROR,
      httpResponse: checkResponse
    }
  }

  if (hasUpdate(data.lastResult)) {
    // return continue to updateProgress
    return {
      resultType: CheckUpdateResultType.HAS_UPDATE
    }
  }

  if (isSuccessLastResult(data.lastResult)) {
    // no update
    return {
      resultType: CheckUpdateResultType.NO_UPDATE,
      httpResponse: checkResponse
    }
  }
  // error, could not check for update
  return {
    resultType: CheckUpdateResultType.ERROR,
    httpResponse: checkResponse
  }
}

export const createResult = (
  result: CheckUpdateResult
): UpdateProgressResult => {
  if (result.resultType == CheckUpdateResultType.NO_UPDATE) {
    return {
      result: UpdateResult.SUCCESS
    }
  } else {
    return {
      result: UpdateResult.FAILED,
      httpResponse: result.httpResponse,
      commandError: {
        status: true,
        errorType: ErrorProgressType.COMMAND
      }
    }
  }
}
