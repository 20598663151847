import React, { useEffect } from 'react'
import { Button } from '@veneer/core'
import {
  Container,
  Title,
  IconContainer,
  InformationContainer,
  ButtonsContainer
} from './styles'
import PrinterUpdatedIcon from '../../assets/images/SMBGenericPrinterFirmwareUpdate.svg'
import { useAssetsProvider } from '../../context/Language'
import {
  createSimpleUiEvent,
  CustomDataSimpleUiEvent,
  sendSimpleUiEvent
} from '../../../firmwareUpdateControllerCritical/jarvis/dataCollection'
import { Action } from '@jarvis/jweb-core'

interface UpdatePrinterFwProps {
  onClickContinueButton: () => void
}

// Simpleui event parameters
const screenName = 'UpdatePrinterFirmware'

const openScreen: CustomDataSimpleUiEvent = {
  action: Action?.screenDisplayed,
  screenName: screenName
}

const continueButton: CustomDataSimpleUiEvent = {
  action: Action?.controlButtonClicked,
  screenName: screenName,
  controlName: 'GoToUpdateProgress'
}

export const UpdatePrinterFirmware: React.FC<UpdatePrinterFwProps> = ({
  onClickContinueButton
}) => {
  useEffect(() => {
    sendSimpleUiEvent(createSimpleUiEvent(openScreen))
  }, [])

  const assetsProvider = useAssetsProvider()

  const handleUpdateContinueButton = () => {
    onClickContinueButton()
    sendSimpleUiEvent(createSimpleUiEvent(continueButton))
  }

  return (
    <Container>
      <Title>{assetsProvider.getText('update_printer_firmware.title')}</Title>
      <IconContainer>
        <img src={PrinterUpdatedIcon} alt="Printer updated" />
      </IconContainer>
      <InformationContainer>
        <p>
          {assetsProvider.getText(
            'update_printer_firmware.information_container_one'
          )}
        </p>
        <p>
          {assetsProvider.getText(
            'update_printer_firmware.information_container_two'
          )}
        </p>
      </InformationContainer>
      <ButtonsContainer>
        <Button onClick={() => handleUpdateContinueButton()}>
          {assetsProvider.getText('update_printer_firmware.update_continue')}
        </Button>
      </ButtonsContainer>
    </Container>
  )
}
