import { backendMapKeys, commonKeys } from './ErrorKeyMaps'
import { FwUCriticalError } from './ErrorMap'

export const backendMap: Map<string, FwUCriticalError> = new Map([
  [
    backendMapKeys.values._500,
    {
      description: 'Server Error when calling FWU Stage API',
      errorCode: 'EFW03A0001'
    }
  ],
  [
    backendMapKeys.values._400,
    {
      description: 'Bad Request when calling FWU Stage API',
      errorCode: 'EFW03A0002'
    }
  ],
  [
    backendMapKeys.values._401,
    {
      description: 'Unauthorized when calling FWU Stage API',
      errorCode: 'EFW03A0002'
    }
  ],
  [
    backendMapKeys.values._403,
    {
      description: 'Forbidden when calling FWU Stage API',
      errorCode: 'EFW03A0004'
    }
  ],
  [
    commonKeys.values.unknownValueKey,
    {
      description: 'Unknown error when calling FWU Stage API',
      errorCode: 'EFW03A0000'
    }
  ]
])
