import { commonKeys, internalMapKeys } from './ErrorKeyMaps'
import { FwUCriticalError } from './ErrorMap'

export const internalMap: Map<string, FwUCriticalError> = new Map([
  [
    commonKeys.values.unknownValueKey,
    {
      description: 'Unknow internal error',
      errorCode: 'EFW04P0001'
    }
  ],
  [
    internalMapKeys.values.couldNotGetLaunchOptions,
    {
      description: 'Could not get launch options',
      errorCode: 'EFW04P0002'
    }
  ],
  [
    internalMapKeys.values.invalidConnectionForBusinessModel,
    {
      description: 'Invalid connection for business model',
      errorCode: 'EFW04P0003'
    }
  ],
  [
    internalMapKeys.values.couldNotGetDeviceIdFromPrinter,
    {
      description: 'Could not get device Id from printer',
      errorCode: 'EFW04A0000'
    }
  ]
])
