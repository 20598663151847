import { DeviceType } from './../../commons/clients/stageapi/firmware-update-client'
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
import { AxiosResponse } from 'axios'
import { HttpRequest } from '../../commons/plugin/device-http-proxy'
import { findPath, parseJson } from '../../commons/utils/helperMethods'
import { LedmUpdateCheckState } from '../interface/criticalInterface'
import { Step, StepsCoordinator } from '../interface/stepsInterfaces'
import { GetDeviceTypeResult } from '../steps/getDeviceTree/action'
import { hasUpdate } from '../steps/printerUpdateCheck/action'
import { statusPath } from '../steps/printerUpdateCheckLedm/action'

export const enum CriticalStepId {
  WhenJWebReady,
  GetServiceRoutingLaunchOptions,
  NetworkConnectivityCheck,
  PrinterUpdateCheck,
  PrinterUpdateCheckLedm,
  GetDeviceTree,
  RequestCriticalUpdate,
  UpdatePrinterFirmware,
  UpdateProgress,
  NoUpdateRequired,
  FinishProcess
}

export default class CriticalStepCoordinator extends StepsCoordinator {
  public getNextStep(): Step {
    if (!this.currentStep) {
      return this.steps.find(
        (step) => step.stepName === CriticalStepId.WhenJWebReady.toString()
      )
    }
    switch (this.currentStep.stepName) {
      case CriticalStepId.WhenJWebReady.toString():
        return this.steps.find(
          (step) =>
            step.stepName ===
            CriticalStepId.GetServiceRoutingLaunchOptions.toString()
        )
      case CriticalStepId.GetServiceRoutingLaunchOptions.toString():
        return this.steps.find(
          (step) => step.stepName === CriticalStepId.GetDeviceTree.toString()
        )
      case CriticalStepId.GetDeviceTree.toString():
        return this.steps.find(
          (step) =>
            step.stepName === CriticalStepId.RequestCriticalUpdate.toString()
        )
      case CriticalStepId.RequestCriticalUpdate.toString():
        const interrogation = this.steps.find(
          (step) =>
            step.stepName === CriticalStepId.RequestCriticalUpdate.toString()
        )
        const { state, isCritical } = (interrogation.result as AxiosResponse)
          .data

        if (isCritical === true && state === 'firmware-available') {
          const deviceTree = this.steps.find(
            (step) => step.stepName === CriticalStepId.GetDeviceTree.toString()
          )
          const { type } = deviceTree.result as GetDeviceTypeResult

          if (type == DeviceType.LEDM) {
            return this.steps.find(
              (step) =>
                step.stepName ===
                CriticalStepId.PrinterUpdateCheckLedm.toString()
            )
          } else {
            return this.steps.find(
              (step) =>
                step.stepName ===
                CriticalStepId.NetworkConnectivityCheck.toString()
            )
          }
        } else {
          return this.steps.find(
            (step) =>
              step.stepName === CriticalStepId.NoUpdateRequired.toString()
          )
        }
      case CriticalStepId.NetworkConnectivityCheck.toString():
        return this.steps.find(
          (step) =>
            step.stepName === CriticalStepId.PrinterUpdateCheck.toString()
        )
      case CriticalStepId.PrinterUpdateCheck.toString():
        const updateCheckStep = this.steps.find(
          (step) =>
            step.stepName === CriticalStepId.PrinterUpdateCheck.toString()
        )
        const updateCheckResult = (updateCheckStep.result as any).response
        const { lastResult } = parseJson(updateCheckResult as HttpRequest)
        if (hasUpdate(lastResult)) {
          return this.steps.find(
            (step) =>
              step.stepName === CriticalStepId.UpdatePrinterFirmware.toString()
          )
        } else {
          return this.steps.find(
            (step) =>
              step.stepName === CriticalStepId.NoUpdateRequired.toString()
          )
        }

      case CriticalStepId.PrinterUpdateCheckLedm.toString():
        const updateCheckLedmStep = this.steps.find(
          (step) =>
            step.stepName === CriticalStepId.PrinterUpdateCheckLedm.toString()
        )
        const updateCheckLedmResult = (updateCheckLedmStep.result as any)
          .response
        const status = findPath(statusPath, updateCheckLedmResult.body.data)

        if (status == LedmUpdateCheckState.AVAILABLE) {
          return this.steps.find(
            (step) =>
              step.stepName === CriticalStepId.UpdatePrinterFirmware.toString()
          )
        } else {
          return this.steps.find(
            (step) =>
              step.stepName === CriticalStepId.NoUpdateRequired.toString()
          )
        }

      case CriticalStepId.UpdatePrinterFirmware.toString():
        return this.steps.find(
          (step) => step.stepName === CriticalStepId.UpdateProgress.toString()
        )
      case CriticalStepId.NoUpdateRequired.toString():
      case CriticalStepId.UpdateProgress.toString():
        return this.steps.find(
          (step) => step.stepName === CriticalStepId.FinishProcess.toString()
        )
      default:
        throw new Error('Step not found')
    }
  }

  public hasSteps(): boolean {
    return !this.executedSteps.find(
      (stepName) => stepName === CriticalStepId.FinishProcess.toString()
    )
  }
}
