/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { ProgressIndicator } from '@veneer/core'
import { Container } from './styles'
import { useAssetsProvider } from '../../context/Language'
import {
  createSimpleUiEvent,
  CustomDataSimpleUiEvent,
  sendSimpleUiEvent
} from '../../../firmwareUpdateControllerCritical/jarvis/dataCollection'
import { Action } from '@jarvis/jweb-core'

// Simpleui event parameters
const screenName = 'CheckingFirmware'

const openScreen: CustomDataSimpleUiEvent = {
  action: Action?.screenDisplayed,
  screenName: screenName
}

export const CheckingFirmware: React.FC = () => {
  useEffect(() => {
    sendSimpleUiEvent(createSimpleUiEvent(openScreen))
  }, [])

  const assetsProvider = useAssetsProvider()
  return (
    <Container>
      <ProgressIndicator
        appearance="circular"
        behavior="indeterminate"
        css={{ marginBottom: 27 }}
      />
      <p>{assetsProvider.getText('checking_firmware.getting_information')}</p>
    </Container>
  )
}
