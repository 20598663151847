/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import { Button } from '@veneer/core'
import {
  Container,
  Title,
  IconContainer,
  InformationContainer,
  ButtonsContainer,
  Alert
} from './styles'
import PrinterUpdateIcon from '../../assets/images/SMBGenericUpdatePrinterMitigationIcon.svg'
import AlertIcon from '../../assets/images/SMBGenericAlertIcon.svg'
import { useAssetsProvider } from '../../context/Language'
import {
  createSimpleUiEvent,
  CustomDataSimpleUiEvent,
  sendSimpleUiEvent
} from '../../../firmwareUpdateControllerCritical/jarvis/dataCollection'
import { Action } from '@jarvis/jweb-core'

interface UpdatePrinterFwProps {
  onClickUpdatePrinterButton: () => void
}

// Simpleui event parameters
const screenName = 'FirmwareUpdateMitigationNotice'

const openScreen: CustomDataSimpleUiEvent = {
  action: Action?.screenDisplayed,
  screenName: screenName
}

const retryButton: CustomDataSimpleUiEvent = {
  action: Action?.controlButtonClicked,
  screenName: screenName,
  controlName: 'RetryUpdatePrinter'
}

export const FirmwareUpdateMitigationNotice: React.FC<UpdatePrinterFwProps> = ({
  onClickUpdatePrinterButton
}) => {
  const informationContainerTwoRef = useRef<HTMLParagraphElement>()

  const assetsProvider = useAssetsProvider()

  const handleUpdateRetryButton = () => {
    onClickUpdatePrinterButton()
    sendSimpleUiEvent(createSimpleUiEvent(retryButton))
  }

  useEffect(() => {
    sendSimpleUiEvent(createSimpleUiEvent(openScreen))

    informationContainerTwoRef.current.innerHTML = assetsProvider.getText(
      'firmware_update_mitigation_notice.information_container_two'
    )
  }, [])

  return (
    <Container>
      <Title>
        {assetsProvider.getText('firmware_update_mitigation_notice.title')}
      </Title>
      <IconContainer>
        <img src={PrinterUpdateIcon} alt="Printer update mitigation" />
      </IconContainer>
      <InformationContainer>
        <Alert>
          <img src={AlertIcon} alt="Alert" />
          <p>
            {assetsProvider.getText(
              'firmware_update_mitigation_notice.information_container_one'
            )}
          </p>
        </Alert>
        <p ref={informationContainerTwoRef} />
        <p>
          {assetsProvider.getText(
            'firmware_update_mitigation_notice.information_container_three'
          )}
        </p>
      </InformationContainer>
      <ButtonsContainer>
        <Button onClick={() => handleUpdateRetryButton()}>
          {assetsProvider.getText('commons.update_printer')}
        </Button>
      </ButtonsContainer>
    </Container>
  )
}
