import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 125px;

  @media (max-width: 575px) {
    padding-top: 45px;
  }

  @media (min-width: 576px) {
    max-width: 1024px;
  }
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 124px;
  margin-right: 124px;
  flex-grow: 4;

  @media (max-width: 575px) {
    margin-left: 32px;
    margin-right: 32px;
  }

  #icon {
    width: 50px;
    height: 50px;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 575px) {
      margin-top: 45px;
    }
  }
`

export const Title = styled.h1`
  font-size: 3.2rem;
  line-height: 4rem;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #212121;
  margin: 29px 0px 16px 0px;

  @media (max-width: 575px) {
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin: 21px 0px 12px 0px;
  }
`
export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: #212121;
    margin-bottom: 20px;
    text-align: center;
  }

  p.errorCode {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.03rem;
    color: #737373;
  }

  a {
    cursor: pointer;
  }
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  flex-grow: 1;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 36px;
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    line-height: 2rem;
    padding: 14px 24px;
    border-radius: 12px;
    margin: 0px 60px 48px 60px;
    min-width: 140px;
    min-height: 48px;

    @media (max-width: 575px) {
      margin: 16px auto 0px auto;
    }
  }
`
