/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { StepListener } from '../../interface/stepsInterfaces'
import { CriticalUIObject } from '../../interface/criticalInterface'
import { Executor } from '../../executor/Executor'
import { Logger } from '../../../commons/utils/helperMethods'

export const finishProcessListener = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepListener => {
  return {
    onStart: (): Promise<void> => {
      Logger.debug('finishProcessListener.onStart')
      return Promise.resolve()
    },
    onFinished: (payload: object, executor: Executor): Promise<void> => {
      Logger.debug('finishProcessListener.onFinished', payload)
      return Promise.resolve()
    },
    onError: (exception: Error, executor: Executor): Promise<void> => {
      Logger.debug('finishProcessListener.onError', exception)
      return Promise.resolve()
    }
  }
}
