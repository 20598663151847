import React, { useEffect } from 'react'
import { IconMinusCircle, Button } from '@veneer/core'

import {
  Container,
  BodyContainer,
  Title,
  InformationContainer,
  ButtonsContainer
} from './styles'
import { useAssetsProvider } from '../../context/Language'
import {
  createSimpleUiEvent,
  CustomDataSimpleUiEvent,
  sendSimpleUiEvent
} from '../../../firmwareUpdateControllerCritical/jarvis/dataCollection'
import { Action } from '@jarvis/jweb-core'

interface RetryFailedUpdateFwProps {
  onClickGetSupportLink: () => void
  onClickExitSetupButton: () => void
  errorCode?: string
}

// Simpleui event parameters
const screenName = 'RetryFailedUpdateModalError'

const openScreen: CustomDataSimpleUiEvent = {
  action: Action?.screenDisplayed,
  screenName: screenName
}

const supportButton: CustomDataSimpleUiEvent = {
  action: Action?.controlButtonClicked,
  screenName: screenName,
  controlName: 'GetSupportButton'
}

const exitButton: CustomDataSimpleUiEvent = {
  action: Action?.controlButtonClicked,
  screenName: screenName,
  controlName: 'ExitSetupButton'
}

export const RetryFailedUpdateModalError: React.FC<
  RetryFailedUpdateFwProps
> = ({ onClickGetSupportLink, onClickExitSetupButton, errorCode }) => {
  useEffect(() => {
    sendSimpleUiEvent(createSimpleUiEvent(openScreen))
  }, [])

  const assetsProvider = useAssetsProvider()

  const handleSupportButton = () => {
    onClickGetSupportLink()
    sendSimpleUiEvent(createSimpleUiEvent(supportButton))
  }

  const handleExitButton = () => {
    onClickExitSetupButton()
    sendSimpleUiEvent(createSimpleUiEvent(exitButton))
  }

  return (
    <Container>
      <BodyContainer>
        <IconMinusCircle id="icon" color="colorRed6" />
        <Title>
          {assetsProvider.getText('retry_failed_update_modal_error.title')}
        </Title>
        <InformationContainer>
          <p className="body">
            {assetsProvider.getText(
              'retry_failed_update_modal_error.primary_message_one'
            )}
          </p>
          <p className="body">
            {assetsProvider.getText(
              'retry_failed_update_modal_error.primary_message_two'
            )}
          </p>
          {errorCode ? <p className="errorCode">({errorCode})</p> : null}
        </InformationContainer>
      </BodyContainer>

      <ButtonsContainer>
        <Button appearance="secondary" onClick={() => handleExitButton()}>
          {assetsProvider.getText('commons.exit_setup')}
        </Button>
        <Button onClick={() => handleSupportButton()}>
          {assetsProvider.getText('commons.get_support')}
        </Button>
      </ButtonsContainer>
    </Container>
  )
}
