/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Executor } from '../executor/Executor'
import { CriticalUIObject, UIState } from '../interface/criticalInterface'
import { CheckingFirmware } from '../components/CheckingFirmware'
import { UpdatePrinterFirmware } from '../components/UpdatePrinterFirmware'
import { UpdateProgress } from '../components/UpdateProgress'
import { Loading } from '../components/Loading'
import { FirmwareUpdateMitigationNotice } from '../components/FirmwareUpdateMitigationNotice'
import { Action } from '@jarvis/jweb-core'
import { CustomDataSimpleUiEvent } from '../jarvis/dataCollection'

interface CriticalRenderObject {
  uiObject: CriticalUIObject
  executor: Executor
}

export const RenderCriticalUI = (renderObject: CriticalRenderObject) => {
  const customClass = renderObject.uiObject.hasJShellLayout ? 'h-100' : 'vh-100'

  return <div className={customClass}>{renderContent(renderObject)}</div>
}

const renderContent = (renderObject: CriticalRenderObject) => {
  const { uiObject, executor } = renderObject
  switch (uiObject.uiState) {
    case UIState.startCriticalUpdateScreen:
      return renderStartCriticalUpdateScreen()
    case UIState.updatePrinterFirmwareScreen:
      return renderUpdatePrinterFirmwareScreen(executor)
    case UIState.UpdateProgressScreen:
      return renderUpdateProgressScreen(uiObject, executor)
    case UIState.loadingScreenUpdateProgressFirstTime:
      return renderLoadingScreenUpdateProgressFirstTime()
    case UIState.loadingScreenUpdateProgressSecondTime:
      return renderLoadingScreenUpdateProgressSecondTime()
    case UIState.mitigationNoticeScreen:
      return renderMitigationNoticeScreen(executor)
    case UIState.errorScreen:
      return renderErrorScreen(uiObject)
    default:
      throw new Error(`Unknown uiObject ${uiObject.uiState}`)
  }
}

function renderStartCriticalUpdateScreen() {
  return <CheckingFirmware />
}

function renderUpdatePrinterFirmwareScreen(executor: Executor) {
  return (
    <UpdatePrinterFirmware
      onClickContinueButton={() => executor.executeNextStep()}
    />
  )
}

function renderUpdateProgressScreen(
  uiObject: CriticalUIObject,
  executor: Executor
) {
  return (
    <UpdateProgress
      status={uiObject.updateProgress.status}
      percentProgress={uiObject.updateProgress.percentProgress}
      onClickContinueButton={() => executor.executeNextStep()}
    />
  )
}

function renderLoadingScreenUpdateProgressFirstTime() {
  const screenName = 'Loading'

  const openScreen: CustomDataSimpleUiEvent = {
    action: Action?.screenDisplayed,
    screenName: screenName,
    screenMode: 'UpdateProgressFirstTime'
  }

  return <Loading openScreenEvent={openScreen} />
}

function renderLoadingScreenUpdateProgressSecondTime() {
  const screenName = 'Loading'

  const openScreen: CustomDataSimpleUiEvent = {
    action: Action?.screenDisplayed,
    screenName: screenName,
    screenMode: 'UpdateProgressSecondTime'
  }

  return <Loading openScreenEvent={openScreen} />
}

function renderMitigationNoticeScreen(executor: Executor) {
  return (
    <FirmwareUpdateMitigationNotice
      onClickUpdatePrinterButton={() => executor.retry()}
    />
  )
}

function renderErrorScreen(uiObject: CriticalUIObject) {
  return uiObject.errorComponent
}
