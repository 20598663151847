import { commonKeys } from './ErrorKeyMaps'
import { ErrorFamily, FwUCriticalError } from './ErrorMap'
import { Logger } from '../../../commons/utils/helperMethods'
import { intDiagMap } from './InternetDiagnostics'
import { autoUpMap } from './AutoUpdate'
import { backendMap } from './Backend'
import { internalMap } from './Internal'

function getIndexOrDefault(
  map: Map<string, FwUCriticalError>,
  index: string
): FwUCriticalError {
  if (map.has(index)) {
    return map.get(index)
  }
  Logger.info('Falling back to default error message for', index)
  return map.get(commonKeys.values.unknownValueKey)
}

/**
 * Get a error object containing ids for localization strings and errorCode of an error response
 * and it's context.
 * @param identifier Error type, HTTP response code or internal error string.
 * @param errorFamily The group this error is related. Defaults to Internal error.
 * @returns The FwUCriticalError for the identifier or it's family default error object if the identifier could not be found.
 */
export function getErrorFor(
  identifier: string,
  errorFamily: ErrorFamily = ErrorFamily.Internal
): FwUCriticalError {
  switch (errorFamily) {
    case ErrorFamily.InternetDiagnostics:
      return getIndexOrDefault(intDiagMap, identifier)
    case ErrorFamily.AutoUpdate:
      return getIndexOrDefault(autoUpMap, identifier)
    case ErrorFamily.BackendCommunication:
      return getIndexOrDefault(backendMap, identifier)
    case ErrorFamily.Internal:
    default:
      return getIndexOrDefault(internalMap, identifier)
  }
}
