/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import FirmwareUpdateCriticalUI from '../src/firmwareUpdateControllerCritical/FirmwareUpdateCriticalUI'
import projectNames from '../src/firmwareUpdateControllerCritical/configs/projectNames'
import LanguageProvider from '../src/firmwareUpdateControllerCritical/context/Language'
import GlobalStyle from '../src/styles/global'
import { Logger } from '../src/commons/utils/helperMethods'

export default function Root(props: any) {
  useEffect(() => {
    Logger.log('FirmwareUpdateCriticalUI flow has been initialized')

    return () => {
      Logger.log('FirmwareUpdateCriticalUI has been unmoutend')
    }
  }, [])

  const mock = props.properties?.mock
  const jShellLayoutKey =
    props.store?.state?.manifest?.services?.onboarding?.stages?.find(
      (element) => element.serviceId == 'jarvis-jwv-critical-fw-update'
    )?.layoutKey ?? true

  const customClass = jShellLayoutKey ? 'h-100' : 'vh-100'

  return (
    <div id={projectNames.packageJsonName} className={customClass}>
      <LanguageProvider>
        <GlobalStyle />
        <FirmwareUpdateCriticalUI
          isMock={mock ?? false}
          hasJShellLayout={jShellLayoutKey}
          {...props}
        />
      </LanguageProvider>
    </div>
  )
}
