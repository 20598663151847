import { CommandObject, HeadersMap } from '../interface/controllerInterface'
import { HttpRequest, HttpRequestBody } from '../plugin/device-http-proxy'
import { deviceProxyResponse } from '../utils/deviceHttpProxyPlugin'

export const responseExecutionEngine = async (
  command: CommandObject,
  sessionId: string,
  allowUserInteraction = false
) => {
  const commandRequest = command.request

  //pass the api response to execute the command
  const options: HttpRequest = {
    path: commandRequest.URI,
    sessionId,
    headers: headerObject(commandRequest.headers),
    method: commandRequest.httpMethod
  }
  const body: HttpRequestBody = { data: '' }

  if (commandRequest.requestPayload) {
    body.data = commandRequest.requestPayload
  }
  options.body = body
  return deviceProxyResponse(options, allowUserInteraction) // executing response using device http proxy plugin
}

const headerObject = (headers: HeadersMap[]) => {
  let headersElement

  headers?.forEach((element) => {
    headersElement = { ...headersElement, [element.name]: element.value }
  })
  return headersElement
}
