import React from 'react'
import { StepListener } from '../../interface/stepsInterfaces'
import { CriticalUIObject, UIState } from '../../interface/criticalInterface'
import { GetDeviceTypeResult } from './action'
import { Logger } from '../../../commons/utils/helperMethods'
import { handleError } from '../../utils/handleError'
import { ErrorFamily } from '../../assets/error/ErrorMap'
import { Executor } from '../../executor/Executor'

export const getDeviceTreeListener = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepListener => {
  return {
    onStart: (): Promise<void> => {
      Logger.debug('getDeviceTreeListener.onStart')
      setUIObject({ ...uiObject, uiState: UIState.startCriticalUpdateScreen })
      return Promise.resolve()
    },
    onFinished: (payload: object, executor: Executor): Promise<void> => {
      Logger.log('getDeviceTreeListener.onFinished', payload)
      const { deviceId, type } = payload as GetDeviceTypeResult
      if (!deviceId || !type) {
        handleError(
          uiObject,
          setUIObject,
          new Error('Could not get deviceId from printer!'),
          executor,
          'couldNotGetDeviceIdFromPrinter',
          ErrorFamily.Internal
        )
        return Promise.resolve()
      }

      executor.executeNextStep()
      return Promise.resolve()
    },
    onError: (exception: Error, executor: Executor): Promise<void> => {
      Logger.log('getDeviceTreeListener.onError', exception)
      handleError(
        uiObject,
        setUIObject,
        exception,
        executor,
        exception.message,
        ErrorFamily.Internal
      )
      return Promise.resolve()
    }
  }
}
