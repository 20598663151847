import React from 'react'
import { StepListener } from '../../interface/stepsInterfaces'
import { CriticalUIObject, UIState } from '../../interface/criticalInterface'
import { HttpResponse } from '../../../commons/plugin/device-http-proxy'
import { HttpMethodType } from '../../../commons/interface/controllerInterface'
import { handleError } from '../../utils/handleError'
import { ErrorFamily } from '../../assets/error/ErrorMap'
import { Executor } from '../../executor/Executor'
import { Logger, parseJson } from '../../../commons/utils/helperMethods'
import { isSuccessLastResult, isSuccessStatus } from './action'

export interface PayloadTypeUpdateCheck {
  response: object
  isPatchRequest: boolean
}

export const printerUpdateCheckListener = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepListener => {
  return {
    onStart: (): Promise<void> => {
      Logger.debug('printerUpdateCheckListener.OnStart')
      setUIObject({ ...uiObject, uiState: UIState.startCriticalUpdateScreen })
      return Promise.resolve()
    },
    onFinished: (
      payload: PayloadTypeUpdateCheck,
      executor: Executor
    ): Promise<void> => {
      Logger.debug('printerUpdateCheckListener.OnFinished', payload)
      const timePulling = 5000
      const checkResponse = payload.response as HttpResponse
      if (isSuccessStatus(checkResponse.statusCode)) {
        if (checkResponse.method == HttpMethodType.PATCH) {
          setTimeout(() => {
            executor.rerunAction()
          }, timePulling)
        } else {
          const data = parseJson(checkResponse)
          if (data.state == 'processing') {
            setTimeout(() => {
              executor.rerunAction()
            }, timePulling)
          } else if (
            data.state == 'idle' &&
            isSuccessLastResult(data.lastResult)
          ) {
            executor.executeNextStep()
          } else if (
            data.state == 'idle' &&
            !isSuccessLastResult(data.lastResult)
          ) {
            handleError(
              uiObject,
              setUIObject,
              new Error(`The reason for the failure is: ${data.failureReason}`),
              executor,
              data.failureReason,
              ErrorFamily.AutoUpdate // UpdateCheck share errors with AutoUpdate
            )
          }
        }
      } else {
        handleError(
          uiObject,
          setUIObject,
          new Error(`statusCode: ${checkResponse.statusCode}`),
          executor,
          `${checkResponse.statusCode}`,
          ErrorFamily.AutoUpdate // UpdateCheck share errors with AutoUpdate
        )
      }
      return Promise.resolve()
    },
    onError: (exception: Error, executor: Executor): Promise<void> => {
      Logger.debug('printerUpdateCheckListener.OnError', exception)
      handleError(
        uiObject,
        setUIObject,
        exception,
        executor,
        exception.message,
        ErrorFamily.AutoUpdate // UpdateCheck share errors with AutoUpdate
      )
      return Promise.resolve()
    }
  }
}
