import { SHELL_PROPS, StepConfiguration } from '../../interface/stepsInterfaces'

import { CriticalStepId } from '../../coordinator/criticalStepCoordinator'
import { CriticalUIObject } from '../../interface/criticalInterface'
import { finishProcessListener } from './listener'
import { CriticalUIProps } from '../../types/critical'

export const finishProcessStepConfiguration = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepConfiguration => {
  return {
    input: (results: Map<string, object>): object => {
      const criticalUIProps = results.get(SHELL_PROPS) as CriticalUIProps
      const serviceRouting = criticalUIProps.serviceRouting

      const CloseServiceInstancePayload = results.get(
        CriticalStepId.GetServiceRoutingLaunchOptions.toString()
      )['CloseServiceInstancePayload']

      return {
        serviceRouting,
        CloseServiceInstancePayload
      }
    },
    listener: finishProcessListener(uiObject, setUIObject)
  }
}
