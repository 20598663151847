import { StepConfiguration } from '../../interface/stepsInterfaces'
import { CriticalUIObject } from '../../interface/criticalInterface'
import { noUpdateRequiredListener } from './listener'

export const noUpdateRequiredStepConfiguration = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepConfiguration => {
  return {
    input: (): object => ({}),
    listener: noUpdateRequiredListener(uiObject, setUIObject)
  }
}
