import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  align-items: center;

  div[role='progressbar'] {
    margin-top: 70px;
    height: 80px;
    width: 80px;
    border-radius: 0px;

    @media (max-width: 575px) {
      height: 58px;
      width: 58px;
    }
  }

  p {
    font-size: 2.2rem;
    line-height: 2.6rem;
    font-weight: 400;
    text-align: center;
    color: #4e4e4e;
    margin: 10px 142px 10px 142px;

    @media (max-width: 575px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin: 10px 32px 10px 32px;
    }

    @media (max-width: 767px) {
      margin: 10px 50px 10px 50px;
    }

    @media (max-width: 991px) {
      margin: 10px 70px 10px 70px;
    }
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1400px) {
    max-width: 1320px;
  }
`
