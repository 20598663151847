/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { ProgressIndicator, IconWarningAlt, Button } from '@veneer/core'
import PrinterUpdateIcon from '../../assets/images/SMBGenericUpdatePrinterMitigationIcon.svg'
import PrinterUpdatedIcon from '../../assets/images/SMBGenericPrinterUpdated.svg'
import { StatusUpdateProgress } from '../../interface/criticalInterface'
import {
  Container,
  InformationContainer,
  IconContainer,
  Title,
  ButtonsContainer,
  WarningMessage
} from './styles'
import { useAssetsProvider } from '../../context/Language'
import {
  createSimpleUiEvent,
  CustomDataSimpleUiEvent,
  sendSimpleUiEvent
} from '../../../firmwareUpdateControllerCritical/jarvis/dataCollection'
import { Action } from '@jarvis/jweb-core'

interface UpdateProgressProps {
  status: StatusUpdateProgress
  percentProgress?: number
  onClickContinueButton: () => void
}

// Simpleui event parameters
const screenName = 'UpdateProgress'

const openScreen: CustomDataSimpleUiEvent = {
  action: Action?.screenDisplayed,
  screenName: screenName
}

const openScreenStartedMode: CustomDataSimpleUiEvent = {
  action: Action?.screenDisplayed,
  screenName: screenName,
  screenMode: 'Started'
}

const openScreenFinishedMode: CustomDataSimpleUiEvent = {
  action: Action?.screenDisplayed,
  screenName: screenName,
  screenMode: 'Finished'
}

const continueButtonEvent: CustomDataSimpleUiEvent = {
  action: Action?.controlButtonClicked,
  screenName: screenName,
  controlName: 'GoToOobeFlow',
  screenMode: 'Finished'
}

export const UpdateProgress: React.FC<UpdateProgressProps> = ({
  status,
  percentProgress,
  onClickContinueButton
}) => {
  const [titleName, setTitleName] = useState('')
  const [printerIcon, setPrinterIcon] = useState('')
  const [circularProgressIndicator, setCircularProgressIndicator] = useState([])
  const [linearProgressIndicator, setLinearProgressIndicator] = useState([])
  const [warningMessage, setWarningMessage] = useState<string[]>([])
  const [primaryMessage, setPrimaryMessage] = useState<string[]>([])
  const [continueButton, setContinueButton] = useState([])

  const assetsProvider = useAssetsProvider()

  useEffect(() => {
    sendSimpleUiEvent(createSimpleUiEvent(openScreen))
  }, [])

  const handleUpdateContinueButton = () => {
    onClickContinueButton()
    sendSimpleUiEvent(createSimpleUiEvent(continueButtonEvent))
  }

  useEffect(() => {
    switch (status) {
      case StatusUpdateProgress.STARTED:
        sendSimpleUiEvent(createSimpleUiEvent(openScreenStartedMode))
        break

      case StatusUpdateProgress.FINISHED:
        sendSimpleUiEvent(createSimpleUiEvent(openScreenFinishedMode))
        break
    }
  }, [status])

  useEffect(() => {
    switch (status) {
      case StatusUpdateProgress.STARTED:
        setContinueButton([])
        setTitleName(assetsProvider.getText('update_progress.started.title'))
        setPrinterIcon(PrinterUpdateIcon)
        setLinearProgressIndicator([
          <>
            <ProgressIndicator
              appearance="linear"
              behavior="determinate"
              value={percentProgress}
            />
          </>
        ])
        setCircularProgressIndicator([])
        setWarningMessage([
          assetsProvider.getText('update_progress.started.warning_message_one')
        ])
        setPrimaryMessage([
          assetsProvider.getText('update_progress.started.primary_message_one'),
          assetsProvider.getText('update_progress.started.primary_message_two')
        ])
        break

      case StatusUpdateProgress.FINISHED:
        setLinearProgressIndicator([])
        setCircularProgressIndicator([])
        setWarningMessage([])
        setTitleName(assetsProvider.getText('update_progress.finished.title'))
        setPrinterIcon(PrinterUpdatedIcon)
        setPrimaryMessage([
          assetsProvider.getText('update_progress.finished.primary_message_one')
        ])
        setContinueButton([
          <>
            <ButtonsContainer>
              <Button onClick={() => handleUpdateContinueButton()}>
                {assetsProvider.getText('commons.continue')}
              </Button>
            </ButtonsContainer>
          </>
        ])
        break

      case StatusUpdateProgress.NONE:
        setTitleName('')
        setPrinterIcon('')
        setCircularProgressIndicator([])
        setLinearProgressIndicator([])
        setWarningMessage([])
        setPrimaryMessage([])
        setContinueButton([])
        break
    }
  }, [status, percentProgress])
  return (
    <Container>
      {titleName && <Title key={titleName}>{titleName}</Title>}
      {printerIcon && (
        <IconContainer>
          <img src={printerIcon} alt="Printer" />
          {linearProgressIndicator && <>{linearProgressIndicator}</>}
        </IconContainer>
      )}
      <InformationContainer>
        {warningMessage &&
          warningMessage.map((text) => (
            <>
              <WarningMessage key={text}>
                <IconWarningAlt size={22} color="colorOrange6" /> {text}
              </WarningMessage>
            </>
          ))}
        {circularProgressIndicator && <>{circularProgressIndicator}</>}
        {primaryMessage &&
          primaryMessage.map((text) => (
            <>
              <p key={text}>{text}</p>
            </>
          ))}
      </InformationContainer>
      {continueButton && <>{continueButton}</>}
    </Container>
  )
}
