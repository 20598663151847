import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  align-items: center;

  div[role='progressbar'] {
    height: 80px;
    width: 80px;
    border-radius: 0px;

    @media (max-width: 575px) {
      height: 58px;
      width: 58px;
    }
  }
`
