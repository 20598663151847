import React from 'react'
import { StepListener } from '../../interface/stepsInterfaces'
import { isObjectEmpty, Logger } from '../../../commons/utils/helperMethods'
import { CriticalUIObject, UIState } from '../../interface/criticalInterface'
import { JWebPromise } from '@jarvis/jweb-core'
import { Executor } from '../../executor/Executor'
import { ErrorFamily } from '../../assets/error/ErrorMap'
import { handleError } from '../../utils/handleError'

export const whenJWebReadyCriticalListener = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepListener => {
  return {
    onStart: (): Promise<void> => {
      Logger.debug('whenJWebReadyListener.onStart')
      setUIObject({ ...uiObject, uiState: UIState.startCriticalUpdateScreen })
      return Promise.resolve()
    },
    onFinished: async (payload: object, executor: Executor): Promise<void> => {
      Logger.debug('whenJWebReadyListener.onFinished', payload)
      // TODO validate before casting
      const jweb = payload as JWebPromise

      const Auth = jweb.Plugins.Auth
      const ServiceRouting = jweb.Plugins.ServiceRouting
      const EventService = jweb.Plugins.EventService

      let deviceInfo = undefined
      try {
        deviceInfo = await jweb.Plugins.Device.getInfo()
      } catch (error) {
        Logger.error('Error getting device info', error)
      }
      Logger.log('validation', !Auth || !ServiceRouting || !EventService)
      Logger.log('deviceInfo', deviceInfo)
      if (
        isObjectEmpty(Auth) ||
        isObjectEmpty(ServiceRouting) ||
        isObjectEmpty(EventService)
      ) {
        handleError(
          uiObject,
          setUIObject,
          new Error('Invalid environment. JWeb plugins not available'),
          executor,
          'invalidEnvironment',
          ErrorFamily.AutoUpdate
        )
        return Promise.resolve()
      }
      Logger.log(Auth, ServiceRouting, EventService)

      executor.executeNextStep()
      return Promise.resolve()
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onError: (exception: Error, executor: Executor): Promise<void> => {
      Logger.error('whenJWebReadyListener.onError', exception)
      Logger.log('state', { ...uiObject })

      setUIObject({
        ...uiObject,
        uiState: UIState.errorScreen,
        errorComponent: <p>Could not setup app</p>
      })
      return Promise.resolve()
    }
  }
}
