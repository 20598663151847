import React from 'react'
import { StepListener } from '../../interface/stepsInterfaces'
import {
  CriticalUIObject,
  LedmUpdateCheckState,
  UIState
} from '../../interface/criticalInterface'
import { HttpResponse } from '../../../commons/plugin/device-http-proxy'
import { HttpMethodType } from '../../../commons/interface/controllerInterface'
import { handleError } from '../../utils/handleError'
import { ErrorFamily } from '../../assets/error/ErrorMap'
import { Executor } from '../../executor/Executor'
import { findPath, Logger } from '../../../commons/utils/helperMethods'
import { isSuccessStatus, statusPath } from './action'

interface PayloadType {
  response: object
  isPatchRequest: boolean
}

export const printerUpdateCheckLedmListener = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepListener => {
  return {
    onStart: (): Promise<void> => {
      Logger.debug('printerUpdateCheckLedmListener.OnStart')
      setUIObject({ ...uiObject, uiState: UIState.startCriticalUpdateScreen })
      return Promise.resolve()
    },
    onFinished: (payload: PayloadType, executor: Executor): Promise<void> => {
      Logger.debug('printerUpdateCheckLedmListener.OnFinished', payload)
      const timePulling = 5000
      const checkResponse = payload.response as HttpResponse
      if (isSuccessStatus(checkResponse.statusCode)) {
        if (checkResponse.method == HttpMethodType.PUT) {
          setTimeout(() => {
            executor.rerunAction()
          }, timePulling)
        } else {
          const status = findPath(statusPath, checkResponse.body.data)
          if (status == LedmUpdateCheckState.CHECKING) {
            setTimeout(() => {
              executor.rerunAction()
            }, timePulling)
          } else if (
            status == LedmUpdateCheckState.AVAILABLE ||
            status == LedmUpdateCheckState.NOT_AVAILABLE
          ) {
            executor.executeNextStep()
          } else if (status == LedmUpdateCheckState.CHECK_FAILED) {
            handleError(
              uiObject,
              setUIObject,
              new Error(`The reason for the failure is: ${status}`),
              executor,
              status,
              ErrorFamily.AutoUpdate // UpdateCheck share errors with AutoUpdate
            )
          }
        }
      } else {
        handleError(
          uiObject,
          setUIObject,
          new Error(`statusCode: ${checkResponse.statusCode}`),
          executor,
          `${checkResponse.statusCode}`,
          ErrorFamily.AutoUpdate // UpdateCheck share errors with AutoUpdate
        )
      }
      return Promise.resolve()
    },
    onError: (exception: Error, executor: Executor): Promise<void> => {
      Logger.debug('printerUpdateCheckLedmListener.OnError', exception)
      handleError(
        uiObject,
        setUIObject,
        exception,
        executor,
        exception.message,
        ErrorFamily.AutoUpdate // UpdateCheck share errors with AutoUpdate
      )
      return Promise.resolve()
    }
  }
}
