import { ServiceRoutingPlugin } from '@jarvis/jweb-core'
import { Logger } from '../../../commons/utils/helperMethods'
import Action from '../../interface/action'

interface FinishInput {
  serviceRouting: ServiceRoutingPlugin
  CloseServiceInstancePayload: {
    resultData: {
      result: string
    }
  }
}
export class FinishProcessAction extends Action {
  execute(input: FinishInput): Promise<object> {
    Logger.log(
      'CloseServiceInstancePayload',
      JSON.stringify(input.CloseServiceInstancePayload)
    )
    input.serviceRouting.closeServiceInstance(input.CloseServiceInstancePayload)
    return Promise.resolve({})
  }
}
