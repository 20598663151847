import React, { createContext, useState, useContext, useEffect } from 'react'
import { AssetsProviderFactory } from '../assets/AssetsProviderFactory'
import { AssetsProvider } from '@jarvis/web-assets-provider'
import { deviceHandler } from '../../commons/jarvis/device'
interface AssetsProviderProps {
  assetsProvider: AssetsProvider
}
const AssetsProviderContext = createContext<AssetsProviderProps>({
  assetsProvider: null
})

export default function LanguageProvider({ children }) {
  const [assetsProvider, setAssetsProvider] = useState<AssetsProvider>(null)

  async function loadCountryLanguage() {
    const languageTag = await deviceHandler()
    let countryLanguage: string[] = []
    if (languageTag.includes('-')) {
      countryLanguage = languageTag.split('-')
    } else if (languageTag.includes('_')) {
      countryLanguage = languageTag.split('_')
    } else return

    // Fix: Language support with format other than: ISO 639-1
    // Uses en-US language when not supported.
    const useDefaultLanguage =
      countryLanguage[0].length !== 2 || countryLanguage[1].length !== 2
    const language = useDefaultLanguage ? 'en' : countryLanguage[0]
    const country = useDefaultLanguage ? 'US' : countryLanguage[1]

    const assets = AssetsProviderFactory.create(language, country)
    setAssetsProvider(assets)
  }

  useEffect(() => {
    loadCountryLanguage()
  }, [])

  return (
    <AssetsProviderContext.Provider
      value={{
        assetsProvider
      }}
    >
      {children}
    </AssetsProviderContext.Provider>
  )
}

export function useAssetsProvider() {
  const context = useContext(AssetsProviderContext)
  if (!context)
    throw new Error('useAssetsProvider must be used within a LanguageProvider')
  const { assetsProvider } = context
  return assetsProvider
}
