import Action from '../../interface/action'
import { HttpMethodType } from '../../../commons/interface/controllerInterface'
import {
  HttpRequest,
  HttpResponse
} from '../../../commons/plugin/device-http-proxy'
import { deviceProxyResponse } from '../../../commons/utils/deviceHttpProxyPlugin'
import { parseJson } from '../../../commons/utils/helperMethods'

export const connectivityCheck = async (
  input: ConnectivityCheckInput
): Promise<object> => {
  const options: HttpRequest = {
    path: '/cdm/network/v1/internetDiagnostics',
    sessionId: input.sessionId,
    method: HttpMethodType.GET,
    body: { data: '' }
  }
  if (input.isPatchRequest) {
    options.method = HttpMethodType.PATCH
    options.body.data = '{"state": "processing"}'
    const response = (await deviceProxyResponse(options, true)) as HttpResponse
    if (response.statusCode == 200 || response.statusCode == 202) {
      return { response, isPatchRequest: false }
    } else {
      return { response, isPatchRequest: true }
    }
  }

  const response = (await deviceProxyResponse(options)) as HttpResponse
  if (response.statusCode == 200) {
    const { lastResult } = parseJson(response)
    if (lastResult == 'failed' || lastResult == 'connected') {
      return { response, isPatchRequest: true }
    } else {
      return { response, isPatchRequest: false }
    }
  }
  return { response, isPatchRequest: true }
}

interface ConnectivityCheckInput {
  sessionId: string
  isPatchRequest: boolean
}

export class NetworkConnectivityCheckAction extends Action {
  execute(input: object): Promise<object> {
    const result = connectivityCheck(input as ConnectivityCheckInput)
    return Promise.resolve(result)
  }
}
