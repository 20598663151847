import { intDiagMapKeys, commonKeys } from './ErrorKeyMaps'
import { FwUCriticalError } from './ErrorMap'

export const intDiagMap: Map<string, FwUCriticalError> = new Map([
  [
    intDiagMapKeys.values.proxyNotConfigured,
    {
      description:
        'A proxy server address is needed by your network to access the internet. For products that have WPAD OFF and the DHCP server has sent an Auto-proxy URL via option 252 indicating that there is a proxy server on the network, this check know that a proxy address is needed',
      errorCode: 'EFW01U0005'
    }
  ],
  [
    intDiagMapKeys.values.httpRedirect,
    {
      description:
        'Http Redirect error recived when doing internet diagnostics',
      errorCode: 'EFW01U0001'
    }
  ],
  [
    intDiagMapKeys.values.other,
    {
      description: 'Other error recived when doing internet diagnostics',
      errorCode: 'EFW01U0002'
    }
  ],
  [
    intDiagMapKeys.values.networkNotConnected,
    {
      description: 'The network adapter is not connected to the network.',
      errorCode: 'EFW01U0006'
    }
  ],
  [
    intDiagMapKeys.values.duplicateIp,
    {
      description: 'Duplicate Ip recived when doing internet diagnostics',
      errorCode: 'EFW01A0007'
    }
  ],
  [
    intDiagMapKeys.values.proxyConnectionFailed,
    {
      description: 'Connection attempt to the proxy server failed',
      errorCode: 'EFW01U0008'
    }
  ],
  [
    intDiagMapKeys.values.proxyURLResolutionFailed,
    {
      description:
        'DNS resolution of the proxy server URL configured on the printer failed.',
      errorCode: 'EFW01U0009'
    }
  ],
  [
    intDiagMapKeys.values.proxyRequiresAuthentication,
    {
      description:
        'Indicates the Proxy server needs authentication and the user name and password fields are not configured.',
      errorCode: 'EFW01U0010'
    }
  ],
  [
    intDiagMapKeys.values.proxyCredentialsIncorrect,
    {
      description: 'Indicates the proxy username or password is incorrect',
      errorCode: 'EFW01U0011'
    }
  ],
  [
    intDiagMapKeys.values.ipNotRoutable,
    {
      description:
        'The printer is configured with a link-local IPV4 address (Auto IP) or has IPv6 link local address',
      errorCode: 'EFW01U0012'
    }
  ],
  [
    intDiagMapKeys.values.dnsNotConfigured,
    {
      description:
        'Indicates that the DNS address has not been configured on the printer',
      errorCode: 'EFW01U0013'
    }
  ],
  [
    intDiagMapKeys.values.dnsNotResolved,
    {
      description: 'Unable to resolve the DNS name of the dedicated server URL',
      errorCode: 'EFW01U0014'
    }
  ],
  [
    intDiagMapKeys.values.dnsNotReachable,
    {
      description: 'The DNS address cannot be reached',
      errorCode: 'EFW01A0003'
    }
  ],
  [
    intDiagMapKeys.values.gatewayNotConfigured,
    {
      description: 'The printer is not configured with a gateway address',
      errorCode: 'EFW01A0015'
    }
  ],
  [
    intDiagMapKeys.values.gatewayNotReachable,
    {
      description: 'The gateway address cannot be pinged.',
      errorCode: 'EFW01A0004'
    }
  ],
  [
    commonKeys.values.unknownValueKey,
    {
      description: 'Unknown error during intenert dignostics',
      errorCode: 'EFW01A0000'
    }
  ]
])
