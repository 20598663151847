/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { ProgressIndicator } from '@veneer/core'
import { Container } from './styles'
import {
  createSimpleUiEvent,
  CustomDataSimpleUiEvent,
  sendSimpleUiEvent
} from '../../../firmwareUpdateControllerCritical/jarvis/dataCollection'

interface LoadingProps {
  openScreenEvent: CustomDataSimpleUiEvent
}

export const Loading: React.FC<LoadingProps> = ({ openScreenEvent }) => {
  useEffect(() => {
    sendSimpleUiEvent(createSimpleUiEvent(openScreenEvent))
  }, [])

  return (
    <Container>
      <ProgressIndicator appearance="circular" behavior="indeterminate" />
    </Container>
  )
}
