/* eslint-disable react-hooks/rules-of-hooks */
import { StepConfiguration } from '../../interface/stepsInterfaces'
import { CriticalStepId } from '../../coordinator/criticalStepCoordinator'
import { AuthPlugin } from '@jarvis/jweb-core'
import { useBaseUrl } from '../../../commons/utils/useBaseUrl'
import { AuthProvider } from '../../../commons/utils/authProvider'
import { requestCriticalFwListener } from './listener'
import { CriticalUIObject } from '../../interface/criticalInterface'
import { GetDeviceTypeResult } from '../getDeviceTree/action'
const BASE_URL = useBaseUrl()

export const requestCriticalUpdateStepConfiguration = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepConfiguration => {
  return {
    input: (results: Map<string, object>): object => {
      const Auth = results.get(CriticalStepId.WhenJWebReady.toString())[
        'Plugins'
      ].Auth as AuthPlugin

      const { deviceId, type } = results.get(
        CriticalStepId.GetDeviceTree.toString()
      ) as GetDeviceTypeResult
      return {
        baseUrl: BASE_URL,
        jarvisAuthProvider: new AuthProvider(Auth),
        deviceId,
        type,
        filter: {
          isCritical: true
        }
      }
    },
    listener: requestCriticalFwListener(uiObject, setUIObject)
  }
}
