/* eslint-disable @typescript-eslint/ban-types */
export interface CriticalUIObject {
  uiState: UIState
  updateProgress: {
    status: StatusUpdateProgress
    percentProgress?: number
  }
  isServiceOptionsMock?: boolean
  hasJShellLayout?: boolean
  errorComponent: JSX.Element
}

export enum StatusUpdateProgress {
  STARTED,
  FINISHED,
  NONE,
  ERROR
}

export enum UIState {
  startCriticalUpdateScreen,
  updatePrinterFirmwareScreen,
  UpdateProgressScreen,
  loadingScreenUpdateProgressFirstTime,
  loadingScreenUpdateProgressSecondTime,
  mitigationNoticeScreen,
  errorScreen,
  ledmSkip
}

export enum CloseServiceInstanceResult {
  SUCCESS = 'success',
  FAILURE = 'failure',
  DECLINED = 'declined',
  INELIGIBLE = 'ineligible',
  ALREADY_PERFORMED = 'alreadyPerformed',
  INTERRUPTED = 'interrupted'
}

export enum LedmUpdateCheckState {
  AVAILABLE = 'available',
  NOT_AVAILABLE = 'notAvailable',
  CHECK_FAILED = 'checkFailed',
  CHECKING = 'checking'
}
