import React from 'react'
import {
  CriticalUIObject,
  StatusUpdateProgress,
  UIState
} from '../../interface/criticalInterface'

export class ProgressBar {
  percent: number
  maxPercent: number
  incrementPerSecond: number
  uiObject: CriticalUIObject
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
  timeoutId: NodeJS.Timeout = undefined

  constructor(
    maxPercent: number,
    incrementPerSecond: number,
    uiObject: CriticalUIObject,
    setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
  ) {
    this.percent = 0
    this.maxPercent = maxPercent
    this.uiObject = uiObject
    this.setUIObject = setUIObject
    this.incrementPerSecond = incrementPerSecond
  }

  start() {
    this.timeoutId = setInterval(() => {
      this.updateProgress()
    }, 1000)
  }

  updateProgress() {
    if (this.percent >= this.maxPercent) {
      clearTimeout(this.timeoutId)
    } else {
      this.percent = this.percent + this.incrementPerSecond

      if (this.percent > this.maxPercent) {
        this.percent = this.maxPercent
      }
      this.updateView(this.percent)
    }
  }

  async stop() {
    clearTimeout(this.timeoutId)
    this.percent = this.maxPercent
  }

  updateView(currentPercent: number) {
    this.setUIObject({
      ...this.uiObject,
      uiState: UIState.UpdateProgressScreen,
      updateProgress: {
        status: StatusUpdateProgress.STARTED,
        percentProgress: currentPercent
      }
    })
  }

  completeProgress() {
    this.updateView(100)
  }
}
