export const lauchServiceOptionsMock: object = {
  serviceId: 'openUrl',
  serviceOptions: {
    appSessionId: 'abc123',
    onboardingContext: {
      sessionContext: {
        sessionId: 'a5b67u7r4-1d5d-4604-91ae-ff886879d36a',
        xCorrelationId:
          '[a5b67u7r4-1d5d-4604-91ae-ff886879d36a]-[jarvis-jwv-critical-fw-update]',
        onboardingContext: {
          experience: 'smb',
          entry: 'portalOobe',
          bizModelHint: 'E2E',
          entryUrl:
            'https://admin.hpsmart.com/activate?earlyOobe=easystartwin&printerUuid=abc'
        },
        app: {
          clientId: 'fdaa06d9-1d5d-4604-91ae-ff886879d36d',
          appVersion: 'HP Smart Windows 129.0.3914.0',
          osType: 'windows',
          osVersion: '10.0.17763.1',
          countryRegionIso: 'US',
          locale: 'en_GB',
          container: 'browser',
          exitOobe: {
            firstPrintPdfUrl: 'string',
            activities: [
              {
                name: 'firstPrint',
                enabled: true,
                suggestedOrder: 0
              }
            ]
          }
        },
        device: {
          uuid: 'string',
          productNumber: 'string',
          serialNumber: 'string',
          modelName: 'string',
          bizModel: 'string',
          cloudId: 'string',
          deviceLocation: 'string',
          deviceName: 'string',
          highestResImgUrl: 'string',
          pls: {},
          ioConfigCapability: 'usbOnly'
        },
        user: {
          initialAuthenticationState: 'notAuthenticated',
          currentAuthenticationState: 'notAuthenticated',
          consents: [
            {
              purposeId: 'string',
              state: 'string'
            }
          ]
        },
        tenant: {
          resourceId: 'string',
          accountName: 'string',
          type: 'string'
        },
        derivedAttributes: {
          entryUrl: {
            queryString:
              '?earlyOobe=easystartwin&printerUuid=abc&appName=HP%20EasyStart',
            decodedQueryParams: {
              earlyOobe: 'easystartwin',
              printerUuid: 'abc',
              appName: 'HP EasyStart'
            }
          },
          pls: {
            onboardingCapabilities: {}
          }
        },
        stepperState: [
          {
            stepId: 'onbp-step-assign-ownership',
            status: 'complete',
            order: 0
          }
        ]
      },
      nextService: {
        serviceId: 'jarvis-jwv-critical-fw-update',
        resultUrl: 'https://.../path/to/update/session',
        exitUrl: 'https://.../path/to/redirect/after/onboarding/finishes'
      },
      event: 'string'
    }
  }
}
