import { DeviceType } from '../..'

export interface PrinterSelectedDataInterface {
  PrinterUUID: string
  PrinterProductNumber: string
  Connectivity: ConnectivityType
  Status: StatusType
  SessionId: string
  Token: string
  IsSelected: boolean
}
export interface CommandObject {
  id: string
  request: RequestObject
  retryPolicy: RetryPolicy
  responseEvaluation: EvaluationMap[]
}

export interface RequestObject {
  httpMethod: HttpMethodType
  URI: string
  headers?: HeadersMap[]
  requestPayload?: string
}
export interface RetryPolicy {
  maxRetries: number
  retryInterval: number
}
export interface EvaluationMap {
  responseCodes: number[]
  validationSchemaURI?: string
  extractions?: Extractions[]
  matchExpression?: MatchExpression
  nextCommand?: CommandObject
}
export interface Extractions {
  pattern: string
  patternType: PatternType //'headerName' | 'payloadXpath' | 'payloadJSONPath'
  extractionReference: string
}
export interface MatchExpression {
  extractionReference: string
  successValuesToMatch: string[]
  failureValuesToMatch: string[]
}
export interface HeadersMap {
  name: string
  value: string
}
export interface RecipeObject {
  command: CommandObject
  statusCommand: CommandObject
  cancelCommand: CommandObject
  supportsDynamicSecurity: boolean
  supportsUpgradeByCloudKick: boolean
}

export interface InterrogationResponse {
  deviceUuid?: string
  state: string
  updateType?: string
  shouldAttemptCloudKick?: boolean
  recipe?: { recipeList: RecipeObject[] }
}

export interface InterrogationResponseWithTreeData
  extends InterrogationResponse {
  type: DeviceType
  deviceId: string | object
  printerType: PrinterDeviceType
}

export enum HttpMethodType {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
  PATCH = 'PATCH'
}

export enum ConnectivityType {
  LOCAL = 'local',
  NETWORK = 'network',
  USB = 'usb'
}

export enum StatusType {
  ONLINE = 'online',
  OFFLINE = 'offline'
}

export enum PatternType {
  HEADER_NAME = 'headerName',
  PAYLOAD_XPATH = 'payloadXpath',
  PAYLOAD_JSON_PATH = 'payloadJSONPath'
}

export enum PrinterDeviceType {
  IIC = 'IIC',
  IPH = 'IPH',
  DEFAULT = 'default'
}
