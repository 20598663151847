/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import {
  EventServicePlugin,
  EventServicePluginError,
  JWebPromise,
  SubscriptionHandle,
  WhenJWebReady,
  LaunchServiceOptions,
  ServiceRoutingError,
  ServiceInstance,
  Service,
  ServiceId,
  ServiceList,
  ServiceInstanceEvent,
  EventInfo,
  AuthPlugin,
  AuthPluginError,
  ServiceRoutingPlugin,
  DeviceInfo,
  AuthPluginLoginChangeEventData,
  AccessToken,
  TokenType
} from '@jarvis/jweb-core'
import { getSubscriber } from '../../commons/utils/eventProvider'
import { responseExecutionEngine } from '../../commons/commandExecution/response-execution-engine'
import {
  CommandObject,
  ConnectivityType,
  InterrogationResponse,
  InterrogationResponseWithTreeData,
  PrinterSelectedDataInterface,
  StatusType
} from '../../commons/interface/controllerInterface'
import {
  triggerFirmwareUpdateResponse,
  updateInterrogationResponse
} from '../../commons/utils/api'
import { AuthProvider } from '../../commons/utils/authProvider'
import { checkCookieExists, Logger } from '../../commons/utils/helperMethods'
import { useBaseUrl } from '../../commons/utils/useBaseUrl'
import { getDeviceType } from '../../commons/controller/device-discovery-tree'
import { getPrinterType } from '../../commons/controller/identify-printer-type'

let Auth: AuthPlugin
let ServiceRouting: ServiceRoutingPlugin
let EventService: EventServicePlugin
export let printerSelectedEventSessionID: string
export const interrogationApiResponses = new Map()
const baseUrl = useBaseUrl()
let firstTimeGettingServices = true
export let deviceInfo: DeviceInfo
let appInstanceId: string
let printerUUID: string
const printerSessionIds: string[] = []
let isHomeScreenFocused = false
const DeviceSelectedForOobe = 'DeviceSelectedForOobe'

export const FirmwareUpdateController = async () => {
  let printerSelectedEventSubscriber:
    | SubscriptionHandle
    | EventServicePluginError = null

  let homeScreenFocusChangedEventSubscriber:
    | SubscriptionHandle
    | EventServicePluginError = null

  let serviceRoutingEventSubscriber:
    | SubscriptionHandle
    | EventServicePluginError = null

  let deviceSelectedForOOBESubscriber:
    | SubscriptionHandle
    | EventServicePluginError = null

  const response: JWebPromise = await WhenJWebReady
  Logger.log('Jweb plugin response', response)
  Auth = response.Plugins.Auth
  ServiceRouting = response.Plugins.ServiceRouting
  EventService = response.Plugins.EventService
  try {
    deviceInfo = await response.Plugins.Device.getInfo()
  } catch (error) {
    Logger.error('Error getting device info', error)
  }

  if (!Auth || !ServiceRouting || !EventService) {
    throw new Error('JWeb plugins not available')
  }
  Logger.log(Auth, ServiceRouting, EventService)

  // get appInstanceId
  const params = new URLSearchParams(window.location.search)
  if (!params.has('appInstanceId')) {
    Logger.error('Error getting appInstanceId')
    return
  }
  appInstanceId = params.get('appInstanceId')
  Logger.log('appInstanceId', appInstanceId)

  //subscribe for printer Selected event
  printerSelectedEventSubscriber = await getSubscriber(
    EventService,
    'PrinterSelected',
    handlePrinterSelectedEvent
  )
  if (printerSelectedEventSubscriber) {
    //success
    Logger.log('printerselected event is subscribed')
  }

  // Login Change Event
  Auth.addListener('LoginChange', loginChangeLegacyHandler)
  Auth.addListener('com.hp.jarvis.auth.event.login.change', loginChangeHandler)

  //subscribe for homeScreenFocusChanged event
  homeScreenFocusChangedEventSubscriber = await getSubscriber(
    EventService,
    'HomeScreenFocusChanged',
    handleHomeScreenFocusChangedEvent
  )
  if (homeScreenFocusChangedEventSubscriber) {
    Logger.log('HomeScreenFocusChanged event is subscribed')
  }
  serviceRoutingEventSubscriber = await getSubscriber(
    EventService,
    'ServiceRouting',
    handleserviceRoutingEvent
  )
  if (serviceRoutingEventSubscriber) {
    //serviceRoutingeventsubscriber
    Logger.log(
      'service routing event subscriber',
      serviceRoutingEventSubscriber
    )
  }

  deviceSelectedForOOBESubscriber = await getSubscriber(
    EventService,
    DeviceSelectedForOobe,
    handleDeviceSelectedForOOBE
  )
  if (deviceSelectedForOOBESubscriber) {
    Logger.log(
      'DeviceSelectedForOOBE event subscriber',
      deviceSelectedForOOBESubscriber
    )
  }

  return 'react-firmware-update-controller'
}

export const clearCacheAndSessionId = () => {
  Logger.log('Clearing cache and session Id')
  printerSelectedEventSessionID = null
  interrogationApiResponses.clear()
}

interface LoginStateLegacy {
  isLoggedIn: boolean
}

// clear any request to FwuStage because user may re-select updateType
export const handleDeviceSelectedForOOBE = () => {
  Logger.log('Clearing Interrogation responses due to OOBE')
  interrogationApiResponses.clear()
}

export const loginChangeLegacyHandler = (loginState: LoginStateLegacy) => {
  //will run once something related to login is changed
  Logger.log(`login state (legacy) changed. ${JSON.stringify(loginState)}`)
  clearCacheAndSessionId()
}

export const loginChangeHandler = (
  eventData: AuthPluginLoginChangeEventData
) => {
  Logger.log(`login state changed. ${JSON.stringify(eventData)}`)
  clearCacheAndSessionId()
}

export const handleHomeScreenFocusChangedEvent = async (data) => {
  const loginStatus = await isLogged()
  if (!loginStatus) return

  isHomeScreenFocused = data.eventData.hasFocus

  Logger.log('HomeScreenFocusChanged event is published')
  Logger.log('homescreenfocuschanged event', data)
  if (data.eventData.hasFocus) {
    Logger.log('home screen got focus', data)
    if (
      printerSelectedEventSessionID &&
      printerSelectedEventSessionID !== null
    ) {
      Logger.log(
        'Starting startFwUpdateFlow with sessionId ',
        printerSelectedEventSessionID
      )
      await startFwUpdateFlow(printerSelectedEventSessionID)
    } else {
      Logger.log('no printer sessionId to be resumed')
    }
  } else {
    Logger.log('Home Screen lost focus')
    removeSessionIdFromPrinterSessionIds(printerSelectedEventSessionID)
  }
}

export const isServiceAvailable = async (
  serviceId: ServiceId
): Promise<Service | null> => {
  Logger.log('is service available method is called')
  const services = await getAvailableServices()
  const uiWebAppServiceId = services?.find(
    (service: Service) => service.id === serviceId
  )
  Logger.log('services', services)
  Logger.log('ui webapp serviceid', uiWebAppServiceId)
  if (uiWebAppServiceId) {
    return uiWebAppServiceId
  }
  return null
}

export const getAvailableServices = async (): Promise<Service[]> => {
  Logger.log('get available services method is called')
  const serviceList = await ServiceRouting.getServices({
    requestRefresh: firstTimeGettingServices
  })
  Logger.log('serviceList', serviceList)
  if (hasServiceRoutingError(serviceList)) {
    Logger.error(
      `Error while getting available services: ${
        (serviceList as ServiceRoutingError).errorType
      } - ${(serviceList as ServiceRoutingError).reason}`
    )
    return [] as Service[]
  } else {
    firstTimeGettingServices = false
    return (serviceList as ServiceList).services
  }
}

//subscribe for handlePrinterSelected event
export const handlePrinterSelectedEvent = async (data) => {
  const loginStatus = await isLogged()
  if (!loginStatus) return

  Logger.log('PrinterSelectedEvent', data)
  //data sent by publisher is in data.eventData
  const printerSelectedData = data.eventData as PrinterSelectedDataInterface

  if (printerSelectedData.IsSelected === false) {
    printerSelectedEventSessionID = null
    return
  }

  //record the sessionId from the most recently received printerSelected event
  printerSelectedEventSessionID = printerSelectedData.SessionId
  //record the PrinterUUID from the printerselected event
  printerUUID = printerSelectedData.PrinterUUID
  if (
    printerSelectedData.Connectivity === ConnectivityType.NETWORK &&
    printerSelectedData.Status === StatusType.ONLINE &&
    printerSelectedData.SessionId
  ) {
    try {
      if (!isHomeScreenFocused) {
        Logger.log('Home Screen lost focus before FW update flow start')
        return null
      }
      await startFwUpdateFlow(printerSelectedEventSessionID)
    } catch (error) {
      Logger.error('Error when doing update', error)
    } finally {
      removeSessionIdFromPrinterSessionIds(printerSelectedEventSessionID)
    }
  }
}

export const handleserviceRoutingEvent = (data) => {
  //data sent by publisher is in data.eventData
  Logger.log('ServiceRouting Event is published!', data)
}

const hasServiceRoutingError = (
  serviceRoutingError: any | ServiceRoutingError
) => {
  Logger.log('hasservice routing error method', serviceRoutingError)
  return (serviceRoutingError as ServiceRoutingError).errorType !== undefined
}

//subscribe for jarvisEventServiceInstanceClosed event
export const createUiWebAppServiceClosedSubscriber = async (
  serviceInstance: ServiceInstance
) => {
  Logger.log('service instance closed event method')
  const closedEventName = ServiceInstanceEvent.jarvisEventServiceInstanceClosed
  const subscriber = await getSubscriber(
    EventService,
    closedEventName,
    uiWebAppClosedEventHandler,
    serviceInstance.eventPublisherId
  )
  Logger.log('subscriber', subscriber)
}

export const uiWebAppClosedEventHandler = async (eventInfo: EventInfo) => {
  Logger.log('UI web app service instance closed')
  const { subscriber, publisherId, eventName, eventData } = eventInfo
  Logger.log(
    `Event with ${eventName}/${subscriber}/${publisherId}/${eventData} was fired!}`
  )
  Logger.log('instanceclosedEventhandler event info', eventInfo)
  /**
   * Event Data format from Firmware Update controller UI
      eventData: {
        extraData: {
          reason: "fwUpdateDeclined"
          sessionId: "bar"
        }
      }
   */
  Logger.log('eventdata', eventData)
  const eventSessionId = eventData['extraData']?.sessionId

  if (!eventSessionId) {
    Logger.error(
      'Did not receive session Id in service closed event extra data'
    )
    removeSessionIdFromPrinterSessionIds(printerSelectedEventSessionID)
    return
  }

  if (eventSessionId !== printerSelectedEventSessionID) {
    Logger.log(
      'Selected printer changed while UI was showing; stopping post-UI flow'
    )
    removeSessionIdFromPrinterSessionIds(printerSelectedEventSessionID)
    return
  }

  const interrogationApiResponse = interrogationApiResponses.get(eventSessionId)

  // Safety check, in case somehow the cache got cleared while the UI web app was open
  if (!interrogationApiResponse) {
    Logger.warn(
      'Did not find cached interrogation response for session id ' +
        eventSessionId
    )
    removeSessionIdFromPrinterSessionIds(eventSessionId)
    return
  }

  Logger.log('Cached interrogationApiResponse', interrogationApiResponse)

  //when UI is closed, update FW if user accepts the FWU
  if (eventData['extraData']?.reason === 'fwUpdateAccepted') {
    Logger.log('User accepted FW Update, so doing it')
    await doFwUpdate(eventSessionId, interrogationApiResponse)
  }
}

export const doFwUpdate = async (sessionId: string, updateData: any) => {
  Logger.log('Doing FW Update')
  const jarvisAuthProvider = new AuthProvider(Auth)
  Logger.log('jarvis auth provider', jarvisAuthProvider)
  const { deviceId, type, shouldAttemptCloudKick } = updateData
  if (shouldAttemptCloudKick) {
    const loginStatus = await isLogged()
    if (!loginStatus) return

    try {
      await triggerFirmwareUpdateResponse(
        baseUrl,
        jarvisAuthProvider,
        deviceId,
        type
      )
      Logger.log('Successfully triggered FW update - Done!')
      //FW update will happen, so clear cached data for this printer
      interrogationApiResponses.delete(printerSelectedEventSessionID)
      return
    } catch (error) {
      //check this error object has status or not
      if (error.response?.status === 401) {
        Logger.warn('Got unauthorized when triggering cloud FW update.')
        clearCacheAndSessionId()
        return
      }
      Logger.error('Non auth error in trigger update', error.response || error)
    } finally {
      //clear FwU Stage service response for device session from cache
      interrogationApiResponses.delete(sessionId)
      removeSessionIdFromPrinterSessionIds(sessionId)
      Logger.log(
        'interrogationApi response cache cleared for session ' + sessionId
      )
    }
  }

  // Cloud FW update either failed above, or was not attempted, so attempt FW update
  // via recipe execution
  const commandObject = updateData.recipe.recipeList[0].command
  //call the recipe execution engine
  executeRecipeCommand(commandObject, sessionId)
}

// Either returns the (possibly newly) cached value, or null if there was an error getting updated
// values
export const ensureCachePopulatedForSession = async (
  sessionId: string
): Promise<InterrogationResponseWithTreeData | null> => {
  Logger.log('Ensuring cache contains interrogation and device data.')
  //cache of updateInterrogation responses
  if (!interrogationApiResponses.has(sessionId)) {
    Logger.log('Data not cached for ' + sessionId + ', so doing interrogation.')
    const { deviceId, type } = await getDeviceType(sessionId) // getting the type and deviceId from the deviceHttp proxy plugin
    Logger.log(`trigger update, ${deviceId}, ${type}`)
    //after any asynchronous operation within the startFwUpdateFlow function, we need to see if the current session Id is different than the passed in sessionId, and stop execution if so.
    if (!deviceId) {
      Logger.log('could not get deviceId from printer!')
      return null
    }
    if (!isHomeScreenFocused) {
      Logger.log('HomseScreen lost focus')
      return null
    }
    if (sessionId !== printerSelectedEventSessionID) {
      Logger.log(
        'sessionId no longer matches current session after getting printer trees'
      )
      return null
    }
    const printerType = await getPrinterType(type, sessionId)
    Logger.log('printerType IIC/IPH/DEFAULT', printerType)
    Logger.log('baseurl', baseUrl)
    try {
      const jarvisAuthProvider = new AuthProvider(Auth)
      Logger.log('jarvisAuthprovider', jarvisAuthProvider)
      const updateInterrogationRes = await updateInterrogationResponse(
        baseUrl,
        jarvisAuthProvider,
        deviceId,
        type
      ) // calling the interrogation api
      if (!isHomeScreenFocused) {
        Logger.log(
          'HomseScreen lost focus after calling FW Update Interrogation'
        )
        return null
      }
      if (sessionId !== printerSelectedEventSessionID) {
        Logger.log(
          'sessionId no longer matches current session after calling FW Update Interrogation'
        )
        return null
      }

      const updateInterrogationResData: InterrogationResponse =
        updateInterrogationRes.data
      Logger.log('interrogation api response', updateInterrogationResData)
      //cache deviceId, type with the Fw Update Interrogation response.
      const responseWithDeviceId: InterrogationResponseWithTreeData = {
        ...updateInterrogationResData,
        deviceId,
        type,
        printerType
      }
      interrogationApiResponses.set(sessionId, responseWithDeviceId)
      return responseWithDeviceId
    } catch (error) {
      //check this error object has status or not
      if (error.response?.status === 401) {
        Logger.warn('Got unauthorized when triggering cloud FW update.')
        clearCacheAndSessionId()
      }
      Logger.error('error in Interrogation API Response', error)
    }
    return null
  } else {
    return interrogationApiResponses.get(
      sessionId
    ) as InterrogationResponseWithTreeData
  }
}

// This starts the flow.  Should happen every time a new printer is selected or
// the home screen regains focus
export const startFwUpdateFlow = async (sessionId: string) => {
  Logger.log('Starting the flow...')

  // check to see if the current session id is in the sessionsRunning array
  //If the current session id IS in the sessionsRunning array, just stop

  if (printerSessionIds.includes(sessionId)) {
    Logger.log(
      `current sessionid ${sessionId} in printersessionID array ${printerSessionIds} , so ending flow`
    )
    return
  }

  //If the current session id IS NOT in the sessionsRunning array, add the current session Id to sessionsRunning and continue
  printerSessionIds.push(sessionId)
  Logger.log(
    `current sessionid is ${sessionId} added in sessionID array ${printerSessionIds}`
  )

  // Get the cached response (either previously cached or cached above)
  const interrogationApiResponse = await ensureCachePopulatedForSession(
    sessionId
  )
  if (!interrogationApiResponse) {
    Logger.log(
      'No cached data for session id ' + sessionId + ', so ending flow'
    )
    return
  }

  /*
   very old printers firmware updates are not being released.

   According to the requirements- Any printers having the recipe as
   { "supportsDynamicSecurity": true, "supportsUpgradeByCloudKick": false }
   The firmware update UI should not be popped up.
    */

  const { updateType, recipe, printerType } = interrogationApiResponse
  const supportsDynamicSecurity = recipe?.recipeList[0]?.supportsDynamicSecurity
  const supportsUpgradeByCloudKick =
    recipe?.recipeList[0]?.supportsUpgradeByCloudKick

  if (supportsDynamicSecurity && !supportsUpgradeByCloudKick) {
    Logger.log(
      'old printers firmware updates are not being released, so stoping the flow'
    )
    return
  }

  const command = recipe?.recipeList[0]?.command

  if (!command) {
    Logger.log('no command object is present, so stoping the flow')
    return
  }

  //open the FwU ui app
  if (updateType === 'notify') {
    Logger.log('Update type is notify.  Launching UI web app')
    //If that cookie exists, do not show the UI web app
    const cookieExists = checkCookieExists(printerUUID)
    Logger.log(`cookiesExist for the ${printerUUID} is ${cookieExists}`)
    if (cookieExists) return
    const serviceIfAvailable = await isServiceAvailable('FirmwareUpdateUI')
    if (serviceIfAvailable) {
      const launchServiceOptions: LaunchServiceOptions = {
        serviceId: serviceIfAvailable.id,
        serviceOptions: {
          sessionId, // sessionid of the printer
          printerType, //IIC/IPH/Default
          appInstanceId
        }
      }

      if (!isHomeScreenFocused) {
        Logger.log('HomseScreen lost focus before launching UI web app service')
        return null
      }
      //launching the FwU ui web app
      let serviceInstance: ServiceInstance | ServiceRoutingError = null
      serviceInstance = await ServiceRouting.launchService(launchServiceOptions)

      if (hasServiceRoutingError(serviceInstance)) {
        Logger.error(
          `Error while launching service: ${
            (serviceInstance as ServiceRoutingError).errorType
          } - ${(serviceInstance as ServiceRoutingError).reason}`
        )
      } else {
        //subscribe for closed event handler
        createUiWebAppServiceClosedSubscriber(
          serviceInstance as ServiceInstance
        )
        Logger.log(
          `Launched service ${
            (serviceInstance as ServiceInstance).serviceId
          } as launched service instance ${
            (serviceInstance as ServiceInstance).instanceId
          }`
        )
      }
    } else {
      Logger.error('FirmwareUpdateUI service not an available service!')
    }
  } else if (updateType === 'auto') {
    Logger.log('Update type is auto.  Doing FW Update')
    await doFwUpdate(sessionId, interrogationApiResponse)
  }
}

/*
Whenever the flow for a session stops, for whatever reason
(i.e. exception thrown, change in sessionId detected, successfully finished the flow, ...),
remove the session Id for the flow that was running from the sessionsRunning array
*/
const removeSessionIdFromPrinterSessionIds = (sessionId: string) => {
  const index = printerSessionIds.indexOf(sessionId)
  if (index > -1) printerSessionIds.splice(index, 1)

  Logger.log(
    `sessionId ${sessionId} removed from printerSessionIds array ${printerSessionIds}`
  )
}

const executeRecipeCommand = async (
  commandObject: CommandObject,
  sessionId: string
) => {
  const commandListResponse = await responseExecutionEngine(
    commandObject,
    sessionId
  ) // returning the execution recipe response
  Logger.log('response execution engine response', commandListResponse)
}

const isLogged = async (): Promise<boolean> => {
  const isLoggedInResponse = await Auth.isLoggedIn()
  const getTokenResponse = await Auth.getToken({
    tokenProviderOptions: {
      tokenType: TokenType.user,
      allowNetworkAccess: true,
      allowUserInteraction: false,
      requireFreshToken: false,
      skipTokenRefresh: true
    }
  })

  const isLoggedIn = hasAuthPluginError(isLoggedInResponse)
    ? false
    : (
        isLoggedInResponse as {
          value: boolean
        }
      ).value

  const hasToken = hasAuthPluginError(getTokenResponse)
    ? false
    : (getTokenResponse as AccessToken).tokenValue?.length > 0

  const result = isLoggedIn && hasToken
  Logger.log(
    result
      ? 'The user is logged'
      : `The user is not logged, Auth.inLoggedIn: ${isLoggedIn} and hasToken: ${hasToken}`
  )
  return result
}

const hasAuthPluginError = (
  response: { value: boolean } | AuthPluginError | AccessToken
) => {
  Logger.log('Check if AuthPlugin response is error:', response)
  return (response as AuthPluginError).error !== undefined
}
