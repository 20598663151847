import Action from '../../interface/action'
import { HttpMethodType } from '../../../commons/interface/controllerInterface'
import {
  HttpRequest,
  HttpResponse
} from '../../../commons/plugin/device-http-proxy'
import { deviceProxyResponse } from '../../../commons/utils/deviceHttpProxyPlugin'
import { findPath } from '../../../commons/utils/helperMethods'
import { LedmUpdateCheckState } from '../../interface/criticalInterface'

export const printerUpdateCheckLedm = async (
  input: PrinterUpdateCheckLedmInput
): Promise<object> => {
  const options: HttpRequest = {
    path: '/FirmwareUpdate/WebFWUpdate/State',
    sessionId: input.sessionId,
    method: HttpMethodType.GET,
    body: { data: '' },
    allowUserInteraction: true
  }
  if (input.isPutRequest) {
    // Sends check request
    options.method = HttpMethodType.PUT
    options.path = '/FirmwareUpdate/WebFWUpdate/StateAction'
    options.body.data = stateActionPayload('check')
    const response = (await deviceProxyResponse(options, true)) as HttpResponse
    if (isSuccessStatus(response.statusCode)) {
      return { response, isPutRequest: false }
    }
    return { response, isPutRequest: true }
  }

  const response = (await deviceProxyResponse(options)) as HttpResponse
  if (isSuccessStatus(response.statusCode)) {
    const status = findPath(statusPath, response.body.data)
    if (
      status == LedmUpdateCheckState.AVAILABLE ||
      status == LedmUpdateCheckState.NOT_AVAILABLE ||
      status == LedmUpdateCheckState.CHECK_FAILED
    ) {
      // Reset the status when the check is finished
      const resetResponse = await resetStateAction(options.sessionId)
      if (!isSuccessStatus(resetResponse.statusCode)) {
        return { response: resetResponse, isPutRequest: true }
      }
      return { response, isPutRequest: true }
    } else {
      return { response, isPutRequest: false }
    }
  }
  return { response, isPutRequest: true }
}

interface PrinterUpdateCheckLedmInput {
  sessionId: string
  isPutRequest: boolean
}

export class PrinterUpdateCheckLedmAction extends Action {
  execute(input: object): Promise<object> {
    const result = printerUpdateCheckLedm(input as PrinterUpdateCheckLedmInput)
    return Promise.resolve(result)
  }
}

const successStatus = [200, 202, 204]
export const isSuccessStatus = (statusCode: number): boolean => {
  return successStatus.includes(statusCode)
}

const resetStateAction = async (sessionId: string) => {
  const options: HttpRequest = {
    path: '/FirmwareUpdate/WebFWUpdate/StateAction',
    sessionId: sessionId,
    method: HttpMethodType.PUT,
    body: {
      data: stateActionPayload('reset')
    }
  }
  return (await deviceProxyResponse(options, true)) as HttpResponse
}

const stateActionPayload = (command: string) => {
  return `<?xml version="1.0" encoding="UTF-8"?> <fwudyn:FirmwareUpdateState xsi:schemaLocation="http://www.hp.com/schemas/imaging/con/ledm/firmwareupdatedyn/2010/12/12 FirmwareUpdateDyn.xsd" xmlns:fwudyn="http://www.hp.com/schemas/imaging/con/ledm/firmwareupdatedyn/2010/12/12" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"> <fwudyn:UpdateAction>${command}</fwudyn:UpdateAction> </fwudyn:FirmwareUpdateState>`
}

export const statusPath =
  "/*[local-name()='FirmwareUpdateState']/*[local-name()='Status']"
