import { StepConfiguration } from '../../interface/stepsInterfaces'

import { CriticalUIObject } from '../../interface/criticalInterface'
import { whenJWebReadyCriticalListener } from './listener'

export const whenJWebReadStepConfiguration = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepConfiguration => {
  return {
    input: (): object => ({}),
    listener: whenJWebReadyCriticalListener(uiObject, setUIObject)
  }
}
