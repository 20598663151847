/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { StepListener } from '../../interface/stepsInterfaces'
import { CriticalUIObject, UIState } from '../../interface/criticalInterface'
import { Logger } from '../../../commons/utils/helperMethods'
import { Executor } from '../../executor/Executor'

export const updatePrinterFirmwareListener = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepListener => {
  return {
    onStart: (): Promise<void> => {
      Logger.debug('updatePrinterFirmwareListener.onStart')
      setUIObject({
        ...uiObject,
        uiState: UIState.updatePrinterFirmwareScreen
      })
      return Promise.resolve()
    },
    onFinished: async (payload: object, executor: Executor): Promise<void> => {
      Logger.debug('updatePrinterFirmwareListener.onFinished', payload)
      return Promise.resolve()
    },
    onError: (exception: Error, executor: Executor): Promise<void> => {
      Logger.debug('updatePrinterFirmwareListener.onError', exception)
      return Promise.resolve()
    }
  }
}
