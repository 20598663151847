import React from 'react'
import { StepListener } from '../../interface/stepsInterfaces'
import { CriticalUIObject, UIState } from '../../interface/criticalInterface'
import { LaunchServiceOptions, ServiceRoutingError } from '@jarvis/jweb-core'
import { Logger } from '../../../commons/utils/helperMethods'
import { lauchServiceOptionsMock } from '../../mock/serviceOptions'
import { ErrorFamily } from '../../assets/error/ErrorMap'
import { handleError } from '../../utils/handleError'
import { handleErrorRetryFailed } from '../../utils/handleErrorRetryFailed'
import { Executor } from '../../executor/Executor'
import { internalMapKeys } from '../../../firmwareUpdateControllerCritical/assets/error/ErrorKeyMaps'

interface ServiceOptions {
  serviceOptions: {
    onboardingContext: {
      sessionContext: {
        device: {
          connectivityToApp: string
          bizModel: string
        }
      }
    }
  }
}

export const getServiceRoutingLaunchOptionsListener = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepListener => {
  return {
    onStart: (): Promise<void> => {
      Logger.debug('getServiceRoutingLaunchOptionsListener.OnStart')
      setUIObject({ ...uiObject, uiState: UIState.startCriticalUpdateScreen })
      return Promise.resolve()
    },
    onFinished: (payload: object, executor: Executor): Promise<void> => {
      Logger.debug('getServiceRoutingLaunchOptionsListener.OnFinished', payload)
      if ((payload as ServiceRoutingError).errorType !== undefined) {
        handleError(
          uiObject,
          setUIObject,
          new Error('Could not get launch options'),
          executor,
          'couldNotGetLaunchOptions',
          ErrorFamily.Internal
        )
        return Promise.resolve()
      }

      const serviceOptions = payload as ServiceOptions
      if (
        serviceOptions?.serviceOptions?.onboardingContext?.sessionContext?.device?.connectivityToApp?.toUpperCase() ===
          'USB' &&
        serviceOptions?.serviceOptions?.onboardingContext?.sessionContext?.device?.bizModel?.toUpperCase() ===
          'E2E'
      ) {
        Logger.log(
          'Cannot perform critical update on printers with bizModel equals E2E and connectivityToApp equals USB.',
          payload
        )
        handleErrorRetryFailed(
          uiObject,
          setUIObject,
          executor,
          internalMapKeys.values.invalidConnectionForBusinessModel,
          ErrorFamily.Internal
        )
        return Promise.resolve()
      }

      if (uiObject.isServiceOptionsMock === true) {
        Logger.log(
          'getServRoutingLaunchOpts.onFinished mock payload',
          JSON.stringify(lauchServiceOptionsMock)
        )
        executor.updateStepResult(lauchServiceOptionsMock)
        executor.executeNextStep()
        return Promise.resolve()
      }

      if (
        (payload as LaunchServiceOptions).serviceOptions['appSessionId'] ===
        undefined
      ) {
        Logger.log('svc routing options', payload)
        handleError(
          uiObject,
          setUIObject,
          new Error('Could not get launch options'),
          executor,
          'couldNotGetLaunchOptions',
          ErrorFamily.Internal
        )
        return Promise.resolve()
      }
      executor.executeNextStep()
      return Promise.resolve()
    },
    onError: (exception: Error, executor: Executor): Promise<void> => {
      Logger.debug('getServiceRoutingLaunchOptionsListener.OnError', exception)
      handleError(
        uiObject,
        setUIObject,
        exception,
        executor,
        exception.message,
        ErrorFamily.Internal
      )
      return Promise.resolve()
    }
  }
}
