import Action from '../../interface/action'
import { HttpMethodType } from '../../../commons/interface/controllerInterface'
import {
  HttpRequest,
  HttpResponse
} from '../../../commons/plugin/device-http-proxy'
import { deviceProxyResponse } from '../../../commons/utils/deviceHttpProxyPlugin'
import { parseJson } from '../../../commons/utils/helperMethods'

export const printerUpdateCheck = async (
  input: PrinterUpdateCheckInput
): Promise<object> => {
  const options: HttpRequest = {
    path: '/cdm/firmwareUpdate/v2/updateCheck',
    sessionId: input.sessionId,
    method: HttpMethodType.GET,
    body: { data: '' },
    allowUserInteraction: true
  }
  if (input.isPatchRequest) {
    options.method = HttpMethodType.PATCH
    options.body.data = '{"state": "processing"}'
    const response = (await deviceProxyResponse(options, true)) as HttpResponse
    if (isSuccessStatus(response.statusCode)) {
      return { response, isPatchRequest: false }
    } else {
      return { response, isPatchRequest: true }
    }
  }

  const response = (await deviceProxyResponse(options)) as HttpResponse
  if (isSuccessStatus(response.statusCode)) {
    const { lastResult } = parseJson(response)
    if (hasLastResult(lastResult)) {
      return { response, isPatchRequest: true }
    } else {
      return { response, isPatchRequest: false }
    }
  }
  return { response, isPatchRequest: true }
}

export interface PrinterUpdateCheckInput {
  sessionId: string
  isPatchRequest: boolean
}

export class PrinterUpdateCheckAction extends Action {
  execute(input: object): Promise<object> {
    const result = printerUpdateCheck(input as PrinterUpdateCheckInput)
    return Promise.resolve(result)
  }
}

const successStatus = [200, 202, 204]
export const isSuccessStatus = (statusCode: number): boolean => {
  return successStatus.includes(statusCode)
}

const updateAvailableResults = [
  'updateAvailable',
  'FwUpdateCheckLastResult_updateAvailable'
]

const successLastResults = [
  'versionUpToDate',
  'FwUpdateCheckLastResult_versionUpToDate',
  ...updateAvailableResults
]

const allResults = [
  'failed',
  'FwUpdateCheckLastResult_failed',
  ...successLastResults
]

export const isSuccessLastResult = (lastResult: string): boolean => {
  return successLastResults.includes(lastResult)
}

export const hasUpdate = (lastResult: string): boolean => {
  return updateAvailableResults.includes(lastResult)
}

const hasLastResult = (lastResult: string): boolean => {
  return allResults.includes(lastResult)
}
