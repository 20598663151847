import { StepConfiguration } from '../../interface/stepsInterfaces'
import { CriticalStepId } from '../../coordinator/criticalStepCoordinator'
import { CriticalUIObject } from '../../interface/criticalInterface'
import { printerUpdateCheckListener } from './listener'

export const printerUpdateCheckStepConfiguration = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepConfiguration => {
  return {
    input: (results: Map<string, object>): object => {
      const { appSessionId } = results.get(
        CriticalStepId.GetServiceRoutingLaunchOptions.toString()
      )['serviceOptions']

      if (
        results.get(CriticalStepId.PrinterUpdateCheck.toString()) == undefined
      ) {
        return { sessionId: appSessionId, isPatchRequest: true }
      } else {
        return {
          sessionId: appSessionId,
          isPatchRequest: results.get(
            CriticalStepId.PrinterUpdateCheck.toString()
          )['isPatchRequest']
        }
      }
    },
    listener: printerUpdateCheckListener(uiObject, setUIObject)
  }
}
