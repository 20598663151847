/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { SHELL_PROPS } from '../interface/stepsInterfaces'
import { Executor } from '../executor/Executor'
import CriticalStepCoordinator from '../coordinator/criticalStepCoordinator'
import {
  WhenJWebReadyStep,
  GetServiceRoutingOptionsStep,
  GetDeviceTreeStep,
  RequestCriticalFwStep,
  NetworkConnectivityCheckStep,
  PrinterUpdateCheckStep,
  PrinterUpdateCheckLedmStep,
  UpdatePrinterFirmwareStep,
  UpdateProgressStep,
  NoUpdateRequiredStep,
  FinishProcessStep
} from '../steps/steps'

import {
  CriticalUIObject,
  StatusUpdateProgress,
  UIState
} from '../interface/criticalInterface'
import { whenJWebReadStepConfiguration } from '../steps/whenJwebReady/configuration'
import { whenJWebReadyCriticalListener } from '../steps/whenJwebReady/listener'

import { getServRoutingOptsStepConfiguration } from '../steps/getLaunchServiceOptions/configuration'
import { getServiceRoutingLaunchOptionsListener } from '../steps/getLaunchServiceOptions/listener'

import { getDeviceTreeListener } from '../steps/getDeviceTree/listener'
import { getDeviceTreeStepConfiguration } from '../steps/getDeviceTree/configuration'

import { requestCriticalUpdateStepConfiguration } from '../steps/requestCritical/configuration'
import { requestCriticalFwListener } from '../steps/requestCritical/listener'

import { networkConnectivityCheckStepConfiguration } from '../steps/networkConnectivityCheck/configuration'
import { networkConnectivityCheckListener } from '../steps/networkConnectivityCheck/listener'

import { printerUpdateCheckStepConfiguration } from '../steps/printerUpdateCheck/configuration'
import { printerUpdateCheckListener } from '../steps/printerUpdateCheck/listener'

import { printerUpdateCheckLedmStepConfiguration } from '../steps/printerUpdateCheckLedm/configuration'
import { printerUpdateCheckLedmListener } from '../steps/printerUpdateCheckLedm/listener'

import { updatePrinterFirmwareConfiguration } from '../steps/updatePrinterFirmware/configuration'
import { updatePrinterFirmwareListener } from '../steps/updatePrinterFirmware/listener'

import { updateProgressConfiguration } from '../steps/updateProgress/configuration'
import { updateProgressListener } from '../steps/updateProgress/listener'

import { noUpdateRequiredStepConfiguration } from '../steps/noUpdateRequired/configuration'
import { noUpdateRequiredListener } from '../steps/noUpdateRequired/listener'

import { finishProcessStepConfiguration } from '../steps/finishProcess/configuration'
import { finishProcessListener } from '../steps/finishProcess/listener'

import { CriticalUIProps } from '../types/critical'

// Configuration
const whenJWebReadyConfig = whenJWebReadStepConfiguration(null, null)
const getServiceRoutingOptionsConfig = getServRoutingOptsStepConfiguration(
  null,
  null
)
const getDeviceTreeConfig = getDeviceTreeStepConfiguration(null, null)
const requestCriticalFwConfig = requestCriticalUpdateStepConfiguration(
  null,
  null
)
const networkConnectivityCheckConfig =
  networkConnectivityCheckStepConfiguration(null, null)
const printerUpdateCheckConfig = printerUpdateCheckStepConfiguration(null, null)
const printerUpdateCheckLedmConfig = printerUpdateCheckLedmStepConfiguration(
  null,
  null
)
const updatePrinterFirmwareConfig = updatePrinterFirmwareConfiguration(
  null,
  null
)
const updateProgressConfig = updateProgressConfiguration(null, null)
const noUpdateRequiredConfig = noUpdateRequiredStepConfiguration(null, null)
const finishProcessConfig = finishProcessStepConfiguration(null, null)

// Steps
const whenJWebReadyStep = new WhenJWebReadyStep(whenJWebReadyConfig)
const getServiceRoutingOptionsStep = new GetServiceRoutingOptionsStep(
  getServiceRoutingOptionsConfig
)
const getDeviceTreeStep = new GetDeviceTreeStep(getDeviceTreeConfig)
const requestCriticalFwStep = new RequestCriticalFwStep(requestCriticalFwConfig)
const networkConnectivityCheckStep = new NetworkConnectivityCheckStep(
  networkConnectivityCheckConfig
)
const printerUpdateCheckStep = new PrinterUpdateCheckStep(
  printerUpdateCheckConfig
)
const printerUpdateCheckLedmStep = new PrinterUpdateCheckLedmStep(
  printerUpdateCheckLedmConfig
)
const updatePrinterFirmwareStep = new UpdatePrinterFirmwareStep(
  updatePrinterFirmwareConfig
)
const updateProgressStep = new UpdateProgressStep(updateProgressConfig)
const noUpdateRequiredStep = new NoUpdateRequiredStep(noUpdateRequiredConfig)
const finishProcessStep = new FinishProcessStep(finishProcessConfig)

const steps = [
  whenJWebReadyStep,
  getServiceRoutingOptionsStep,
  getDeviceTreeStep,
  requestCriticalFwStep,
  networkConnectivityCheckStep,
  printerUpdateCheckStep,
  printerUpdateCheckLedmStep,
  updatePrinterFirmwareStep,
  updateProgressStep,
  noUpdateRequiredStep,
  finishProcessStep
]
const coordinator = new CriticalStepCoordinator(steps)
const executor = new Executor(coordinator)

export const useCriticalController = (props: CriticalUIProps) => {
  const [uiObject, setUIObject] = useState<CriticalUIObject>({
    uiState: UIState.startCriticalUpdateScreen,
    updateProgress: {
      status: StatusUpdateProgress.NONE,
      percentProgress: 0
    },
    isServiceOptionsMock: props?.isMock ?? false,
    errorComponent: <></>,
    hasJShellLayout: props?.hasJShellLayout ?? true
  })

  whenJWebReadyConfig.listener = whenJWebReadyCriticalListener(
    uiObject,
    setUIObject
  )
  getServiceRoutingOptionsConfig.listener =
    getServiceRoutingLaunchOptionsListener(uiObject, setUIObject)

  getDeviceTreeConfig.listener = getDeviceTreeListener(uiObject, setUIObject)

  requestCriticalFwConfig.listener = requestCriticalFwListener(
    uiObject,
    setUIObject
  )

  networkConnectivityCheckConfig.listener = networkConnectivityCheckListener(
    uiObject,
    setUIObject
  )

  printerUpdateCheckConfig.listener = printerUpdateCheckListener(
    uiObject,
    setUIObject
  )

  printerUpdateCheckLedmConfig.listener = printerUpdateCheckLedmListener(
    uiObject,
    setUIObject
  )

  updatePrinterFirmwareConfig.listener = updatePrinterFirmwareListener(
    uiObject,
    setUIObject
  )
  updateProgressConfig.listener = updateProgressListener(uiObject, setUIObject)

  noUpdateRequiredConfig.listener = noUpdateRequiredListener(
    uiObject,
    setUIObject
  )

  finishProcessConfig.listener = finishProcessListener(uiObject, setUIObject)

  executor.resultList.set(SHELL_PROPS, props)

  return { uiObject, executor }
}
