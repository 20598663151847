/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpRequest } from '../plugin/device-http-proxy'
import { appVersion } from './appVersion'
import { appPrefix } from './appPrefix'
import { Buffer } from 'buffer'

export const encodeToBase64 = (data: string) => {
  return Buffer.from(data).toString('base64')
}

export const decodeFromBase64 = (data: string) => {
  return Buffer.from(data, 'base64').toString('utf-8')
}

export const parseJson = (RequestData: HttpRequest) => {
  const systemServiceData = RequestData.body.isBase64Encoded
    ? decodeFromBase64(RequestData.body.data)
    : RequestData.body.data
  return RequestData.body.contentType === 'application/json'
    ? JSON.parse(systemServiceData)
    : systemServiceData
}

//find the path from xml file
export const findPath = (path: string, xsdFile: string) => {
  const xpath = require('xpath')
  const dom = require('@xmldom/xmldom').DOMParser
  const doc = new dom().parseFromString(xsdFile)
  return xpath.select('string(' + path + ')', doc)
}

//find the values from xml file
export const findMultipleValuesinXml = (
  path: string,
  namespace: object,
  xmlFile: string
) => {
  const xpath = require('xpath')
  const dom = require('@xmldom/xmldom').DOMParser
  const doc = new dom().parseFromString(xmlFile)
  const select = xpath.useNamespaces(namespace)

  const nodeValues = select(path, doc)
  const resultValues: string[] = []
  nodeValues.forEach((element) => {
    resultValues.push(element.nodeValue)
  })
  return resultValues
}

const logWithPrefix = (severity: string, ...inputData: any) => {
  const prefix = appPrefix()
  console[severity](`${prefix} - ${appVersion}`, inputData)
}

export const Logger = {
  debug: (...inputData: any) => logWithPrefix('debug', inputData),
  log: (...inputData: any) => logWithPrefix('log', inputData),
  info: (...inputData: any) => logWithPrefix('info', inputData),
  warn: (...inputData: any) => logWithPrefix('warn', inputData),
  error: (...inputData: any) => logWithPrefix('error', inputData)
}

//if cookie does not exist means setcookie
const setCookie = (cname: string, cvalue: string) => {
  const expireTime = Number(
    process.env['REACT_APP_UI_WEB_APP_COOKIE_EXPIRATION']
  )
  const d = new Date()
  d.setTime(d.getTime() + expireTime * 1000)
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${cname}=${cvalue};${expires};path=/`
}

//checkcookie exists if not setcookie with expiration time
export const checkCookieExists = (printerUUID: string): boolean => {
  const cookieName = `${printerUUID}_UI_shown`
  const name = `${cookieName}=`
  const cookieValue = document.cookie
    .split(';')
    .some((item) => item.trim().startsWith(name))
  if (cookieValue) return true
  setCookie(cookieName, printerUUID)
  return false
}

export const sleep = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay))

//find the path from json file
export const getByJSONPath = (path: string, jsonFile: object) => {
  const jp = require('jsonpath')
  const value = jp.query(jsonFile, path)
  return value
}

export const getValueByPatternType = (
  path: string,
  file: object | string,
  patternType: string
) => {
  if (patternType === 'payloadJSONPath') {
    return getByJSONPath(path, file as object)
  } else if (patternType === 'payloadXpath') {
    return findPath(path, file as string)
  } else {
    throw new Error('Unknown patternType')
  }
}

export const isObjectEmpty = (obj: Object) => !Object.keys(obj).length
