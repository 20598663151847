import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 1024px;
  }
`

export const Title = styled.h1`
  display: flex;
  align-self: flex-start;
  flex: 1 1 auto;
  margin: 32px 56px;
  font-style: normal;
  font-weight: 400;
  font-size: 3.2rem;
  line-height: 4rem;
  color: #212121;

  @media (max-width: 575px) {
    align-self: center;
    margin: 32px;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-align: center;
  }
`

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 575px) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
  }

  img {
    width: 208px;
    height: 116px;

    @media (max-width: 575px) {
      width: 172px;
      height: 96px;
    }
  }
`

export const InformationContainer = styled.div`
  flex: 2 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 400;
    text-align: center;
    color: #212121;
    margin: 0px 120px 32px 120px;

    @media (max-width: 575px) {
      margin: 0px 32px 24px 32px;
    }
  }
`
export const Alert = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background: rgba(88, 88, 88, 0.05);
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  margin: 34px 120px 32px 120px;

  @media (max-width: 575px) {
    margin: 0px 32px 24px 32px;
  }

  img {
    margin: 20px 14px 0px 18px;
  }

  p {
    margin: 16px 16px 16px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: left;
    letter-spacing: 0.02rem;
    color: #212121;
  }
`

export const ButtonsContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }

  button {
    font-size: 1.6rem;
    line-height: 2rem;
    margin-right: 60px;
    margin-bottom: 48px;

    @media (max-width: 575px) {
      margin-bottom: 36px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`
