import {
  CommandObject,
  EvaluationMap,
  RetryPolicy,
  InterrogationResponse,
  Extractions
} from '../../commons/interface/controllerInterface'
import {
  DeviceHttpProxyPluginError,
  HttpResponse
} from '../../commons/plugin/device-http-proxy'
import { responseExecutionEngine } from '../../commons/commandExecution/response-execution-engine'
import {
  getValueByPatternType,
  Logger,
  parseJson,
  sleep
} from '../../commons/utils/helperMethods'
import { EventServicePluginError, Publisher } from '@jarvis/jweb-core'
import { getPublisher, publishEvent } from '../../commons/utils/eventProvider'
import getPlugins from '../../commons/jarvis/getPlugins'
import { AxiosResponse } from 'axios'

const UPDATE_TIMEOUT = 1_200_000
const DELAY_BEFORE_STATUS_COMMAND = 10_000
const INTERVAL_DELTA = 1000

export const UpdateProgressPublisherId =
  'com.hp.jarvis.firmware.controller.critical.updateProgress'

export const UpdateProgressEventName =
  'com.hp.jarvis.firmware.controller.critical.updateProgress.eventName'

export enum UpdateResult {
  SUCCESS,
  FAILED,
  CANCELLED,
  NONE
}

export enum ErrorProgressType {
  COMMAND,
  STATUSCOMMAND
}
export interface UpdateProgressResult {
  result: UpdateResult
  httpResponse?: HttpResponse
  errorMessage?: string
  commandError?: {
    status: boolean
    errorType: ErrorProgressType
  }
}

export interface UpdateProgressEventData {
  updateStarted: boolean
}
interface CommandResult {
  result: UpdateResult
  httpResponse: HttpResponse
  errorMessage?: string
}

let updateProgressPublisher: Publisher
let isTimeOutError = false
let timeOutId = null

export const checkAndExtractRecipe = (response: AxiosResponse) => {
  if (response === undefined || response.data === undefined) {
    Logger.debug('update-progress-controller', 'Recipe list is empty')
    throw new Error('Recipe list is empty')
  } else {
    const interrogationResponse = response.data as InterrogationResponse

    if (
      interrogationResponse === undefined ||
      interrogationResponse.recipe === undefined ||
      interrogationResponse.recipe.recipeList === undefined ||
      interrogationResponse.recipe.recipeList.length == 0
    ) {
      Logger.debug('update-progress-controller', 'Recipe list is empty')
      throw new Error('Recipe list is empty')
    } else {
      return interrogationResponse.recipe.recipeList[0]
    }
  }
}

const isDeviceHttpProxyPluginError = (
  value: HttpResponse | DeviceHttpProxyPluginError
): value is DeviceHttpProxyPluginError => {
  return (
    (value as DeviceHttpProxyPluginError).errorType &&
    (value as DeviceHttpProxyPluginError).errorType !== undefined
  )
}

export const validadeResponseCode = (
  evaluationList: EvaluationMap[],
  statusCode: number
) => {
  const evaluationCountToMatch = evaluationList.length
  let evaluationCount = 0

  evaluationList.forEach((evaluation) => {
    if (evaluation.responseCodes.includes(statusCode)) {
      evaluationCount++
    }
  })
  return evaluationCountToMatch == evaluationCount
}

const executeEvaluation = (
  evaluationList: EvaluationMap[],
  httpResponse: HttpResponse
) => {
  const body: object =
    httpResponse.body && httpResponse.body.data
      ? parseJson(httpResponse)
      : undefined

  const commandResult: CommandResult = {
    result: UpdateResult.SUCCESS,
    httpResponse
  }

  const notHadExtractions =
    evaluationList.filter(
      (evaluation) =>
        evaluation.extractions && evaluation.extractions.length > 0
    ).length == 0

  if (notHadExtractions) {
    return commandResult
  }

  evaluationList.every((evaluation) => {
    let extractionsMap = new Map<string, string>()
    const extractionsList = evaluation.extractions

    const successValuesToMatch =
      evaluation?.matchExpression?.successValuesToMatch
    const failureValuesToMatch =
      evaluation?.matchExpression?.failureValuesToMatch

    if (extractionsList?.length > 0) {
      //loop to populate the extractions map
      try {
        extractionsMap = createExtractionsMap(body, extractionsList)
      } catch (error) {
        throw new Error(`Response body validation error: ${error}`)
      }

      const valueForMatch = extractionsMap.get(
        evaluation.matchExpression.extractionReference
      )

      if (failureValuesToMatch?.includes(valueForMatch)) {
        commandResult.result = UpdateResult.FAILED
        return true
      } else if (successValuesToMatch?.includes(valueForMatch)) {
        return true
      } else {
        commandResult.result = UpdateResult.NONE
        return false
      }
    }
  })

  return commandResult
}

const createExtractionsMap = (
  body: object | string,
  extractionsList: Extractions[]
) => {
  if (body === undefined) {
    throw new Error('Body not found in response')
  }
  const extractionsMap = new Map<string, string>()

  extractionsList.forEach((extraction) => {
    const key = extraction.extractionReference
    const value = getValueByPatternType(
      pathAdjustment(extraction.pattern),
      body,
      extraction.patternType
    )

    if (value) {
      extractionsMap.set(key, value.toString())
    } else {
      throw new Error('Value not found in body')
    }
  }) // end forEach extractions
  return extractionsMap
}

const sendEvent = async (updateStarted: boolean) => {
  const eventData: UpdateProgressEventData = { updateStarted }
  await publishEvent(
    updateProgressPublisher,
    UpdateProgressEventName,
    eventData
  )
}

const executeCommand = async (
  command: CommandObject,
  evaluationList: EvaluationMap[],
  sessionId: string
) => {
  const retryPolicy: RetryPolicy = command.retryPolicy
  let lastHttpResponse: HttpResponse

  for (let index = 0; index < retryPolicy.maxRetries; index++) {
    if (isTimeOutError) {
      isTimeOutError = false
      throw new Error(
        `Exceeded timeout for ${command.request.httpMethod} command`
      )
    }
    const httpResponse = await responseExecutionEngine(command, sessionId, true)

    if (isDeviceHttpProxyPluginError(httpResponse)) {
      Logger.debug(
        'Error from DeviceHttpProxy, printer may be rebooting',
        httpResponse
      )
    }

    lastHttpResponse = httpResponse as HttpResponse
    const statusCode = lastHttpResponse?.statusCode

    // DeviceHttpProxy return error if printer is rebooting
    if (
      !isDeviceHttpProxyPluginError(httpResponse) &&
      validadeResponseCode(evaluationList, statusCode)
    ) {
      break
    } else if (retryPolicy.maxRetries == index + 1) {
      Logger.debug(
        'update-progress-controller',
        `Exceeded number of ${retryPolicy.maxRetries} attempts for ${command.request.httpMethod} command`
      )
      throw new Error(
        `Exceeded number of ${retryPolicy.maxRetries} attempts for ${command.request.httpMethod} command`
      )
    } else {
      await sleep(retryPolicy.retryInterval * INTERVAL_DELTA)
    }
  } // End for
  return lastHttpResponse
}

const processCommand = async (command: CommandObject, sessionId: string) => {
  const evaluationList: EvaluationMap[] = command.responseEvaluation
  let commandResult: CommandResult
  let lastHttpResponse: HttpResponse

  try {
    lastHttpResponse = await executeCommand(command, evaluationList, sessionId)

    commandResult = executeEvaluation(
      evaluationList,
      lastHttpResponse as HttpResponse
    )

    if (commandResult.result === UpdateResult.NONE) {
      await sleep(5000)
      commandResult = await processCommand(command, sessionId)
    }
  } catch (error) {
    const errorMessage = (error as Error)?.message
    commandResult = {
      result: UpdateResult.FAILED,
      httpResponse: lastHttpResponse,
      errorMessage: errorMessage
    }
  }

  return commandResult
}

export const setUpdateProgressPublisher = (
  publisher: Publisher | EventServicePluginError
) => {
  if ((publisher as EventServicePluginError).errorType !== undefined)
    throw Error('Could not create publisher')
  updateProgressPublisher = publisher as Publisher
}

export const getDeviceUpdateProgress = async (
  sessionId: string,
  interrogation: AxiosResponse
) => {
  let updateProgressResult: UpdateProgressResult = {
    result: UpdateResult.NONE
  }
  let lastHttpResponse: HttpResponse
  let commandError = {
    status: false,
    errorType: ErrorProgressType.COMMAND
  }

  try {
    const { EventService } = await getPlugins()

    const publisher = await getPublisher(
      EventService,
      UpdateProgressPublisherId
    )

    setUpdateProgressPublisher(publisher)

    const recipe = checkAndExtractRecipe(interrogation)

    const command: CommandObject = recipe.command
    const statusCommand: CommandObject = recipe.statusCommand

    if (command === undefined || statusCommand === undefined) {
      updateProgressPublisher.destroy()
      throw new Error('The command or statusCommand not exists in recipe')
    }
    timeOutId = setTimeout(() => {
      isTimeOutError = true
    }, UPDATE_TIMEOUT)
    const commandResult = await processCommand(command, sessionId)
    lastHttpResponse = commandResult.httpResponse

    if (commandResult.result === UpdateResult.SUCCESS) {
      // Send event to update view in listener and start progress bar
      await sendEvent(true)

      isTimeOutError = false
      clearTimeout(timeOutId)
      timeOutId = setTimeout(() => {
        isTimeOutError = true
      }, UPDATE_TIMEOUT)
      await sleep(DELAY_BEFORE_STATUS_COMMAND) // adding delay before starting pulling GET
      const statusCommandResult = await processCommand(statusCommand, sessionId)
      lastHttpResponse = statusCommandResult.httpResponse

      if (statusCommandResult.result === UpdateResult.SUCCESS) {
        updateProgressResult = {
          result: UpdateResult.SUCCESS,
          httpResponse: lastHttpResponse
        }
      } else {
        commandError = {
          status: true,
          errorType: ErrorProgressType.STATUSCOMMAND
        }
        throw new Error(
          `Failed to execute the status command: ${statusCommandResult.errorMessage}`
        )
      }
    } else {
      commandError = {
        status: true,
        errorType: ErrorProgressType.COMMAND
      }
      throw new Error(
        `Failed to execute the command: ${commandResult.errorMessage}`
      )
    }
  } catch (error) {
    const errorMessage = (error as Error).message
    updateProgressResult = {
      result: UpdateResult.FAILED,
      httpResponse: lastHttpResponse,
      errorMessage,
      commandError
    }
    Logger.debug(`update-progress-controller error', ${errorMessage}`)
  } finally {
    if (updateProgressPublisher) {
      updateProgressPublisher.destroy()
      Logger.debug('update-progress-controller', 'publisher destroyed')
    }
    clearTimeout(timeOutId)
  }

  return updateProgressResult
}

// Temporary function while we wait for the updated ledm recipes.
const pathAdjustment = (pattern: string) => {
  Logger.info('Pattern received in the pathAdjustment function', pattern)
  return pattern == '/fwudyn:Status'
    ? "/*[local-name()='FirmwareUpdateState']/*[local-name()='Status']"
    : pattern
}
