import {
  Action,
  CDMEventData,
  DataCollectionEventNames,
  EventServicePlugin,
  EventServicePluginError,
  Publisher,
  SimpleUiEvent
} from '@jarvis/jweb-core'
import { Logger } from '../../commons/utils/helperMethods'
import getPlugins from '../../commons/jarvis/getPlugins'
import { getPublisher, publishEvent } from '../../commons/utils/eventProvider'
const eventName = DataCollectionEventNames?.cdmEvent
const publisherId = 'com.hp.fwucritical.datacollection.publisher'

// Creates a Publisher and publish event
let eventService: EventServicePlugin
let simpleUiPublisher: Publisher

export interface CustomDataSimpleUiEvent {
  action: Action
  screenName: string
  screenMode?: string
  controlName?: string
}

const commonData = {
  activity: 'FirmwareUpdate-v01',
  version: '1.3.0',
  screenPath: '/ReactFirmwareUpdateController/'
}

export const createSimpleUiEvent = (
  customData: CustomDataSimpleUiEvent
): SimpleUiEvent => {
  return {
    ...commonData,
    ...customData
  }
}

export const sendSimpleUiEvent = async (simpleUiEvent: SimpleUiEvent) => {
  Logger.debug('sendSimpleUiEvent method called')
  try {
    if (!simpleUiEvent) {
      throw new Error('SimpleUi event payload invalid')
    }

    if (!eventService) {
      const availablePlugins = await getPlugins()
      setEventService(availablePlugins.EventService)
    }

    const publisher = await getPublisher(eventService, publisherId)
    setPublisher(publisher)

    await sendSimpleUiEventWithEventService(simpleUiEvent)
  } catch (error) {
    Logger.debug(
      'Error trying to create simpleUi publisher. Error:',
      JSON.stringify(error)
    )
  }
}

const setPublisher = (publisher: Publisher | EventServicePluginError) => {
  if ((publisher as EventServicePluginError).errorType !== undefined)
    throw new Error('Could not create publisher')
  simpleUiPublisher = publisher as Publisher
}

const setEventService = (
  eventServicePlugin: EventServicePlugin | EventServicePluginError
) => {
  if ((eventServicePlugin as EventServicePluginError).errorType !== undefined)
    throw new Error('Could not create eventService')
  eventService = eventServicePlugin as EventServicePlugin
}

async function sendSimpleUiEventWithEventService(simpleUiEvent: SimpleUiEvent) {
  Logger.debug('Sending simpleUi event: ', JSON.stringify(simpleUiEvent))

  const eventData = createEventData(simpleUiEvent)

  // Publishes event
  if (simpleUiPublisher) {
    await publishEvent(simpleUiPublisher, eventName, eventData)
  }
}

const createEventData = (simpleUiEvent: SimpleUiEvent): object => {
  const date = new Date()
  const formattedDate = date.toISOString()
  const cdmEventData: CDMEventData = {
    dateTime: formattedDate,
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    version: '1.4.0',
    eventDetail: simpleUiEvent
  }

  return {
    events: [cdmEventData]
  }
}
