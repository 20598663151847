import { Step, StepConfiguration } from '../interface/stepsInterfaces'
import { CriticalStepId } from '../coordinator/criticalStepCoordinator'
import { WhenJWebReadyAction } from './whenJwebReady/action'
import { GetServiceRoutingLaunchOptionsAction } from './getLaunchServiceOptions/action'
import { GetDeviceTreeAction } from './getDeviceTree/action'
import { InterrogationAction } from './requestCritical/action'
import { NetworkConnectivityCheckAction } from './networkConnectivityCheck/action'
import { PrinterUpdateCheckAction } from './printerUpdateCheck/action'
import { PrinterUpdateCheckLedmAction } from './printerUpdateCheckLedm/action'
import { UpdatePrinterFirmwareAction } from './updatePrinterFirmware/action'
import { NoUpdateRequiredAction } from './noUpdateRequired/action'
import { FinishProcessAction } from './finishProcess/action'
import { UpdateProgressAction } from './updateProgress/action'

export class WhenJWebReadyStep extends Step {
  constructor(configuration: StepConfiguration) {
    super(
      CriticalStepId.WhenJWebReady.toString(),
      configuration,
      new WhenJWebReadyAction()
    )
  }
}

export class GetServiceRoutingOptionsStep extends Step {
  constructor(configuration: StepConfiguration) {
    super(
      CriticalStepId.GetServiceRoutingLaunchOptions.toString(),
      configuration,
      new GetServiceRoutingLaunchOptionsAction()
    )
  }
}

export class GetDeviceTreeStep extends Step {
  constructor(configuration: StepConfiguration) {
    super(
      CriticalStepId.GetDeviceTree.toString(),
      configuration,
      new GetDeviceTreeAction()
    )
  }
}

export class RequestCriticalFwStep extends Step {
  constructor(configuration: StepConfiguration) {
    super(
      CriticalStepId.RequestCriticalUpdate.toString(),
      configuration,
      new InterrogationAction()
    )
  }
}

export class NetworkConnectivityCheckStep extends Step {
  constructor(configuration: StepConfiguration) {
    super(
      CriticalStepId.NetworkConnectivityCheck.toString(),
      configuration,
      new NetworkConnectivityCheckAction()
    )
  }
}

export class PrinterUpdateCheckStep extends Step {
  constructor(configuration: StepConfiguration) {
    super(
      CriticalStepId.PrinterUpdateCheck.toString(),
      configuration,
      new PrinterUpdateCheckAction()
    )
  }
}

export class PrinterUpdateCheckLedmStep extends Step {
  constructor(configuration: StepConfiguration) {
    super(
      CriticalStepId.PrinterUpdateCheckLedm.toString(),
      configuration,
      new PrinterUpdateCheckLedmAction()
    )
  }
}

export class UpdatePrinterFirmwareStep extends Step {
  constructor(configuration: StepConfiguration) {
    super(
      CriticalStepId.UpdatePrinterFirmware.toString(),
      configuration,
      new UpdatePrinterFirmwareAction()
    )
  }
}

export class UpdateProgressStep extends Step {
  constructor(configuration: StepConfiguration) {
    super(
      CriticalStepId.UpdateProgress.toString(),
      configuration,
      new UpdateProgressAction()
    )
  }
}

export class NoUpdateRequiredStep extends Step {
  constructor(configuration: StepConfiguration) {
    super(
      CriticalStepId.NoUpdateRequired.toString(),
      configuration,
      new NoUpdateRequiredAction()
    )
  }
}

export class FinishProcessStep extends Step {
  constructor(configuration: StepConfiguration) {
    super(
      CriticalStepId.FinishProcess.toString(),
      configuration,
      new FinishProcessAction()
    )
  }
}
