import { Logger } from '../../commons/utils/helperMethods'
import {
  SHELL_PROPS,
  Step,
  StepsCoordinator
} from '../interface/stepsInterfaces'

export class Executor {
  coordinator: StepsCoordinator
  resultList: Map<string, object>
  private executionList: Step[]
  private runningStep = false

  constructor(coordinator: StepsCoordinator) {
    this.coordinator = coordinator
    this.resultList = new Map()
    this.executionList = []
  }

  private async executeInternal(step: Step) {
    try {
      await step.configuration.listener.onStart()
      const result = await step.run(this.resultList)
      Logger.log('executeInternal step', { result, step })
      this.resultList.set(step.stepName, result)
      await step.configuration.listener.onFinished(result, this)
    } catch (error) {
      Logger.log('executeInternal error', error)
      step.configuration.listener.onError(error, this)
    } finally {
      // TODO send execution finished
      Logger.debug('step executed')
    }
    if (this.executionList.length > 0) {
      await this.executeInternal(this.executionList.shift())
    } else {
      this.runningStep = false
    }
  }

  private async executeActionAgain(step: Step) {
    try {
      const result = await step.run(this.resultList)
      this.resultList.set(step.stepName, result)
      await step.configuration.listener.onFinished(result, this)
    } catch (error) {
      Logger.log('executeInternal error', error)
      step.configuration.listener.onError(error, this)
    } finally {
      // TODO send execution finished
      Logger.debug('step executed')
    }
    if (this.executionList.length > 0) {
      await this.executeInternal(this.executionList.shift())
    } else {
      this.runningStep = false
    }
  }

  public async executeNextStep() {
    if (this.coordinator.hasSteps()) {
      const step = this.coordinator.next()
      if (this.runningStep) {
        this.executionList.push(step)
      } else {
        this.runningStep = true
        await this.executeInternal(step)
      }
    } else {
      Logger.debug('no steps')
    }
  }

  public async retry() {
    await this.executeInternal(this.coordinator.currentStep)
  }

  public async rerunAction() {
    await this.executeActionAgain(this.coordinator.currentStep)
  }

  public startOver() {
    this.coordinator.reset()
    this.executeNextStep()
  }

  public reset() {
    this.coordinator.reset()
    const props = this.resultList.get(SHELL_PROPS)
    this.resultList.clear()
    this.resultList.set(SHELL_PROPS, props)
  }

  public updateStepResult(
    result: object,
    stepName: string = this.coordinator.currentStep.stepName
  ) {
    this.resultList.set(stepName, result)
  }
}
