import { JarvisAuthProvider } from '@jarvis/web-http'
import { UpdateFilter } from '../clients/stageapi/firmware-update-client'
import { DeviceType, FirmwareUpdateClient } from '../../index'
import { Stack } from '../clients/web-client'

export const updateInterrogationResponse = async (
  stack: Stack | string,
  authProvider: JarvisAuthProvider,
  deviceId: string | object,
  type: DeviceType,
  filter?: UpdateFilter
) => {
  const client = new FirmwareUpdateClient(stack, authProvider)
  const response = await client.updateInterrogation(deviceId, type, filter) // firmware update stage interrogation api response
  return response
}

export const oobeUpdateAvailabilityResponse = (
  stack: Stack | string,
  deviceId: string | object,
  type: DeviceType
) => {
  const client = new FirmwareUpdateClient(stack, null)
  return client.oobeUpdateAvailability(deviceId, type) // firmware update availabitity api response
}

export const triggerFirmwareUpdateResponse = async (
  stack: Stack | string,
  authProvider: JarvisAuthProvider,
  deviceId: string | object,
  type: DeviceType
) => {
  const client = new FirmwareUpdateClient(stack, authProvider)
  const response = await client.triggerFirmwareUpdate(deviceId, type) // Cloud Firmware Update Trigger api response
  return response
}
