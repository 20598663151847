import React, { useEffect } from 'react'
import { FirmwareUpdateController } from './controller/firmware-update-controller'

const FirmwareUpdateControllerUI = () => {
  useEffect(() => {
    FirmwareUpdateController()
  }, [])
  return <></>
}
export default FirmwareUpdateControllerUI
