/* eslint-disable prettier/prettier */
import getPlugins from './getPlugins'
import { isObjectEmpty } from '../utils/helperMethods'

export const deviceHandler = async () => {
  const { Device } = await getPlugins()
  const { locale } = await Device.getInfo()
  let languageTag: string

  if (isObjectEmpty(locale)) {
    languageTag = (await Device.getLanguageCode()).value
    console.warn('The host application is using an old JWebView version.')
  } else languageTag = locale.languageTag

  console.log('Language Code:', languageTag)
  return languageTag
}
