import { JarvisAuthProvider, JarvisWebHttpClient } from '@jarvis/web-http'

export enum Stack {
  dev,
  pie,
  stage,
  prod
}
export abstract class WebClient {
  jarvisWebHttpInstance: JarvisWebHttpClient
  /**
   * Default constructor.
   *
   * @param {union} baseURL the base URL string or Stack
   * @param {JarvisAuthProvider} authProvider the auth provider for requests this client will make
   */
  constructor(baseURL: Stack | string, authProvider: JarvisAuthProvider) {
    let defaultBaseURL: string
    if (typeof baseURL === 'string') {
      defaultBaseURL = `${baseURL}/v1`
    } else {
      defaultBaseURL = this.getAPIURL(baseURL, '/firmware-update-stage/v1')
    }

    this.jarvisWebHttpInstance = new JarvisWebHttpClient({
      defaultBaseURL: defaultBaseURL,
      defaultAuthProvider: authProvider
    })
  }

  private getStackPrefix(stack: Stack): string {
    switch (stack) {
      case Stack.dev:
        return 'dev'
      case Stack.pie:
        return 'pie'
      case Stack.stage:
        return 'stage'
      case Stack.prod:
        return ''
    }
  }

  private getAPIURL(stack: Stack, basePath: string): string {
    const stackPrefix = this.getStackPrefix(stack)
    return (
      'https://' +
      (stackPrefix.length ? stackPrefix + '-' : '') +
      'us1.api.ws-hp.com' +
      basePath
    )
  }
}
