import React from 'react'
import { CriticalUIObject, UIState } from '../interface/criticalInterface'
import { Executor } from '../executor/Executor'
import { RetryFailedUpdateModalError } from '../components/RetryFailedUpdateModalError'
import { ErrorFamily } from '../assets/error/ErrorMap'
import { getErrorFor } from '../assets/error/GetError'
import {
  closeServiceRoutingInstance,
  openSupportURL
} from './errorHandlerHelpers'

export const handleErrorRetryFailed = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>,
  executor: Executor,
  identifier: string,
  errorFamily: ErrorFamily = ErrorFamily.Internal
) => {
  const error = getErrorFor(identifier, errorFamily)

  setUIObject({
    ...uiObject,
    uiState: UIState.errorScreen,
    errorComponent: (
      <RetryFailedUpdateModalError
        onClickGetSupportLink={() => openSupportURL()}
        onClickExitSetupButton={() =>
          closeServiceRoutingInstance(executor, error)
        }
        errorCode={error?.errorCode}
      />
    )
  })
}
