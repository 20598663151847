/* eslint-disable prettier/prettier */
import { JWebPromise, PluginRegistry, WhenJWebReady } from '@jarvis/jweb-core'
import { Logger } from '../utils/helperMethods'

const getPlugins = async (): Promise<PluginRegistry> => {
  const response: JWebPromise = await WhenJWebReady
  Logger.debug('getPlugins', response)
  const ServiceRouting = response.Plugins.ServiceRouting
  const EventService = response.Plugins.EventService
  const Device = response.Plugins.Device

  if (!ServiceRouting || !EventService || !Device) {
    throw 'JWeb is not ready'
  }
  return response.Plugins
}
export default getPlugins
