import { AccessToken, AuthPlugin, TokenType } from '@jarvis/jweb-core'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { Logger } from './helperMethods'

export class AuthProvider implements JarvisAuthProvider {
  accessToken = ''
  Auth: AuthPlugin

  constructor(authPlugin: AuthPlugin) {
    this.Auth = authPlugin
  }

  async getAccessToken(forceRefresh?: boolean) {
    if (this.accessToken.length > 0 && !forceRefresh) {
      return this.accessToken
    }
    if (this.Auth) {
      try {
        const options = {
          tokenProviderOptions: {
            tokenType: TokenType.user,
            allowNetworkAccess: true,
            allowUserInteraction: false,
            requireFreshToken: forceRefresh
          }
        }
        const { tokenValue } = (await this.Auth.getToken(
          options
        )) as AccessToken
        if (tokenValue) {
          this.accessToken = tokenValue
        }
      } catch (err) {
        Logger.log(err.toString())
      }
    }
    return this.accessToken
  }
}
