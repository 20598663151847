/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { RenderCriticalUI } from './renders/RenderCriticalUi'
import { useCriticalController } from './hooks/useCriticalController'
import { useAssetsProvider } from './context/Language'
import { Loading } from './components/Loading'
import { CriticalUIProps } from './types/critical'
import { Action } from '@jarvis/jweb-core'
import { CustomDataSimpleUiEvent } from './jarvis/dataCollection'

const screenName = 'Loading'

const openScreen: CustomDataSimpleUiEvent = {
  action: Action?.screenDisplayed,
  screenName: screenName,
  screenMode: 'CheckingFirmware'
}

const FirmwareUpdateCriticalUI: React.FC<CriticalUIProps> = (
  props: CriticalUIProps
) => {
  const { uiObject, executor } = useCriticalController(props)
  const [isLoadingLanguage, setIsLoadingLanguage] = useState(true)
  const assetsProvider = useAssetsProvider()
  useEffect(() => {
    if (assetsProvider != null) {
      setIsLoadingLanguage(false)
      executor.executeNextStep()
    }
  }, [assetsProvider])
  return (
    <>
      {isLoadingLanguage ? (
        <Loading openScreenEvent={openScreen} />
      ) : (
        RenderCriticalUI({ uiObject, executor })
      )}
    </>
  )
}

export default FirmwareUpdateCriticalUI
