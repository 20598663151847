import { AxiosResponse } from 'axios'
import { WebClient } from '../web-client'
import { deviceInfo } from '../../../firmwareUpdateController/controller/firmware-update-controller'
import { appPrefix } from '../../../commons/utils/appPrefix'

/**
 * Client for making Firmware update stage API calls.
 */
export enum DeviceType {
  LEDM = 'LEDM',
  CDM = 'CDM'
}

export interface UpdateFilter {
  isCritical: boolean
}
export class FirmwareUpdateClient extends WebClient {
  /**
   * Returns the expected schema for the update interrogation response object.
   *
   * @returns schema in object format
   *
   */

  static updateInterrogationSchema(): object {
    return {
      $schema: 'http://json-schema.org/schema#',
      type: 'object',
      additionalProperties: false,
      properties: {
        deviceUuid: {
          type: 'string'
        },
        state: {
          type: 'string'
        },
        updateType: {
          type: 'string'
        },
        shouldAttemptCloudKick: {
          type: 'boolean'
        },
        recipe: {
          $ref: '#/definitions/recipe'
        }
      },
      definitions: {
        recipe: {
          type: 'object',
          properties: {
            recipeList: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  command: {
                    $ref: '#/definitions/command'
                  },
                  statusCommand: {
                    $ref: '#/definitions/command'
                  },
                  cancelCommand: {
                    $ref: '#/definitions/command'
                  },
                  supportsDynamicSecurity: {
                    description: 'Supports dynamic security',
                    type: 'boolean'
                  },
                  supportsUpgradeByCloudKick: {
                    type: 'boolean'
                  }
                },
                additionalProperties: false
              }
            }
          }
        },
        command: {
          type: 'object',
          properties: {
            id: {
              type: 'string'
            },
            request: {
              type: 'object',
              properties: {
                httpMethod: {
                  type: 'string'
                },
                URI: {
                  type: 'string'
                },
                headers: {
                  type: 'array',
                  items: {
                    $ref: '#/definitions/map'
                  }
                },
                requestPayload: {
                  type: 'string'
                }
              },
              required: ['httpMethod', 'URI'],
              additionalProperties: false
            },
            retryPolicy: {
              type: 'object',
              properties: {
                maxRetries: {
                  type: 'number'
                },
                retryInterval: {
                  type: 'number'
                }
              },
              additionalProperties: false
            },
            responseEvaluation: {
              type: 'array',
              items: {
                $ref: '#/definitions/evaluationMap'
              }
            }
          },
          additionalProperties: false
        },
        evaluationMap: {
          type: 'object',
          properties: {
            responseCodes: {
              type: 'array',
              items: {
                type: 'number'
              }
            },
            validationSchemaURI: {
              type: 'string'
            },
            extractions: {
              type: 'array',
              items: {
                $ref: '#/definitions/extraction'
              }
            },
            matchExpression: {
              $ref: '#/definitions/matchExpression'
            },
            nextCommand: {
              $ref: '#/definitions/command'
            }
          },
          additionalProperties: true
        },
        map: {
          type: 'object',
          properties: {
            name: {
              type: 'string'
            },
            value: {
              type: 'string'
            }
          },
          required: ['name'],
          additionalProperties: false
        },
        extraction: {
          type: 'object',
          properties: {
            pattern: {
              type: 'string'
            },
            patternType: {
              type: 'string',
              enum: ['headerName', 'payloadXpath', 'payloadJSONPath']
            },
            extractionReference: {
              type: 'string'
            }
          },
          additionalProperties: false
        },
        matchExpression: {
          type: 'object',
          properties: {
            extractionReference: {
              type: 'string'
            },
            successValuesToMatch: {
              type: 'array',
              items: {
                type: 'string'
              }
            },
            failureValuesToMatch: {
              type: 'array',
              items: {
                type: 'string'
              }
            }
          },
          additionalProperties: false
        }
      },
      anyOf: [
        {
          required: [
            'deviceUuid',
            'state',
            'updateType',
            'shouldAttemptCloudKick',
            'recipe'
          ]
        },
        {
          required: ['state']
        }
      ]
    }
  }
  /**
   * Firmware availability query and trigger
   *
   * @param {string | object } deviceId deviceId of the printer
   * @param { DeviceType } type type of the device
   * @returns Axios response object
   */
  async updateInterrogation(
    deviceId: string | object,
    type: DeviceType,
    filter?: UpdateFilter
  ): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.post({
      url: '/firmwareUpdateInterrogation',
      data: {
        deviceId: {
          type,
          tree: deviceId
        },
        filter
      },
      headers: { 'X-HP-User-Agent': FirmwareUpdateClient.getHeader() },
      // TODO create new validation schema for Critical Update
      ...(filter?.isCritical
        ? {}
        : {
            validationSchema: FirmwareUpdateClient.updateInterrogationSchema()
          })
    })
  }

  oobeUpdateAvailability(
    deviceId: string | object,
    type: DeviceType
  ): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.post({
      url: '/oobeUpdateAvailability',
      data: {
        deviceId: {
          type,
          tree: deviceId
        }
      },
      headers: { 'X-HP-User-Agent': FirmwareUpdateClient.getHeader() }
    })
  }

  /**
   * Trigger a cloud firmware update
   *
   * @param {string | object } deviceId deviceId of the printer
   * @param { DeviceType } type type of the device
   * @returns Axios response object
   */
  async triggerFirmwareUpdate(
    deviceId: string | object,
    type: DeviceType
  ): Promise<AxiosResponse> {
    return this.jarvisWebHttpInstance.post({
      url: '/firmwareUpdateTrigger',
      data: {
        deviceId: {
          type,
          tree: deviceId
        }
      },
      headers: { 'X-HP-User-Agent': FirmwareUpdateClient.getHeader() }
    })
  }

  static getHeader() {
    const platform = deviceInfo?.platform || 'unknown'
    const version = deviceInfo?.appVersion || 'unknown'
    const prefix = appPrefix()
    return `${prefix};${platform};${version}`
  }
}
