import { DeviceType } from '../clients/stageapi/firmware-update-client'
import { HttpMethodType } from '../interface/controllerInterface'
import { HttpRequest, HttpResponse } from '../plugin/device-http-proxy'
import { deviceProxyResponse } from '../utils/deviceHttpProxyPlugin'
import {
  decodeFromBase64,
  encodeToBase64,
  findPath,
  Logger,
  parseJson
} from '../utils/helperMethods'
import { printerSelectedEventSessionID } from '../../firmwareUpdateController/controller/firmware-update-controller'

export const getDeviceType = async (sessionId: string) => {
  return await getDeviceTypeById(sessionId, printerSelectedEventSessionID)
}

export const getDeviceTypeById = async (
  sessionId: string,
  validationId: string
) => {
  let deviceId: string | object
  let type: DeviceType

  // GET /DevMgmt/DiscoveryTree.xml
  const options: HttpRequest = {
    path: '/DevMgmt/DiscoveryTree.xml',
    sessionId,
    method: HttpMethodType.GET
  }

  const ledmResponse = (await deviceProxyResponse(options)) as HttpResponse

  if (validationId === ledmResponse.sessionId) {
    if (ledmResponse.statusCode === 200) {
      // ProductConfigDynPath found at xPath
      // "/ledm:DiscoveryTree/ledm:SupportedTree[./dd:ResourceType='ledm:hpLedmProductConfigDyn']/ledm:ResourceURI"

      Logger.log('LEDM discovery tree', ledmResponse)
      const ledmData = ledmResponse.body.isBase64Encoded
        ? decodeFromBase64(ledmResponse.body.data)
        : ledmResponse.body.data
      // parse LEDM DiscoveryTree for\nProductConfigDynPath
      // GET ProductConfigDynPath
      options.path = findPath(
        "/*[local-name()='DiscoveryTree']/*[local-name()='SupportedTree'][./*[local-name()='ResourceType']='ledm:hpLedmProductConfigDyn']/*[local-name()='ResourceURI']",
        ledmData
      )
      if (options.path) {
        Logger.log('LEDM ProductConfigDyn path', options.path)
        const productConfigDyn = (await deviceProxyResponse(
          options
        )) as HttpResponse
        if (
          validationId === productConfigDyn.sessionId &&
          productConfigDyn.statusCode === 200
        ) {
          Logger.log('LEDM ProductConfigDyn response', productConfigDyn)
          type = DeviceType.LEDM
          deviceId = productConfigDyn.body.isBase64Encoded
            ? productConfigDyn.body.data
            : encodeToBase64(productConfigDyn.body.data) //response (ProductConfigDyn.xml - "printerId"
        }
      } else {
        type = DeviceType.CDM
        options.path = findPath(
          "/*[local-name()='DiscoveryTree']/*[local-name()='SupportedIfc'][./*[local-name()='ResourceType']='cdm:servicesDiscovery']/*[local-name()='ManifestURI']",
          ledmData
        )
        if (options.path) {
          Logger.log('CDM Services Discovery path', options.path)
          const cdmResponse = (await deviceProxyResponse(
            options
          )) as HttpResponse
          if (
            validationId === cdmResponse.sessionId &&
            cdmResponse.statusCode === 200
          ) {
            Logger.log('CDM Services Discovery response', cdmResponse)
            const cdmResponseData = parseJson(cdmResponse)
            const systemServiceIdentityPath = cdmResponseData.services
              .find(
                (svc) =>
                  svc.serviceGun === 'com.hp.cdm.service.system.version.1'
              )
              ?.links?.find((link) => link.rel === 'identity')?.href
            if (systemServiceIdentityPath) {
              Logger.log(
                'CDM system service identity path',
                systemServiceIdentityPath
              )
              options.path = systemServiceIdentityPath
              const systemServiceIdentityJson = (await deviceProxyResponse(
                options
              )) as HttpResponse
              if (
                validationId === systemServiceIdentityJson.sessionId &&
                systemServiceIdentityJson.statusCode === 200
              ) {
                Logger.log(
                  'CDM system service identity response',
                  systemServiceIdentityJson
                )
                deviceId = parseJson(systemServiceIdentityJson)
              }
            } else {
              deviceId = await CDMPathIdentity(options, validationId)
            }
          }
        } else {
          deviceId = await CDMPathIdentity(options, validationId)
        }
      }
    } else {
      type = DeviceType.CDM
      deviceId = await CDMPathIdentity(options, validationId)
    }
  }
  Logger.log('Tree Type', type)
  Logger.log('Tree', deviceId)
  return { deviceId, type }
}

const CDMPathIdentity = async (options: HttpRequest, validationId: string) => {
  options.path = '/cdm/system/v1/identity'
  Logger.log('CDM system service identity path', options.path)
  const systemServiceIdentityJson = (await deviceProxyResponse(
    options
  )) as HttpResponse
  let deviceId: string
  if (
    validationId === systemServiceIdentityJson.sessionId &&
    systemServiceIdentityJson.statusCode === 200
  ) {
    Logger.log(
      'CDM system service identity response',
      systemServiceIdentityJson
    )
    deviceId = parseJson(systemServiceIdentityJson)
  }

  return deviceId
}
