export enum ErrorFamily {
  InternetDiagnostics,
  AutoUpdate,
  BackendCommunication,
  Internal
}

export interface FwUCriticalError {
  description: string
  errorCode: string
}
