import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import React from 'react'
import { Navigate, Route, Routes, HashRouter as Router } from 'react-router-dom'
import FirmwareUpdateControllerUI from './firmwareUpdateController/FirmwareUpdateControllerUI'
export { FirmwareUpdateController } from './firmwareUpdateController/controller/firmware-update-controller'
export {
  FirmwareUpdateClient,
  DeviceType
} from './commons/clients/stageapi/firmware-update-client'
export { Stack } from './commons/clients/web-client'

if (document.getElementById('root'))
  ReactDOM.render(
    <>
      <Router>
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/" element={<FirmwareUpdateControllerUI />} />
        </Routes>
      </Router>
    </>,
    document.getElementById('root')
  )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
