/* eslint-disable react-hooks/rules-of-hooks */
import { StepConfiguration } from '../../interface/stepsInterfaces'
import { CriticalStepId } from '../../coordinator/criticalStepCoordinator'
import { CriticalUIObject } from '../../interface/criticalInterface'
import { getDeviceTreeListener } from './listener'
import { Logger } from '../../../commons/utils/helperMethods'

export const getDeviceTreeStepConfiguration = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepConfiguration => {
  return {
    input: (results: Map<string, object>): object => {
      Logger.log('getDeviceTreeStepConfiguration', results)
      const { appSessionId } = results.get(
        CriticalStepId.GetServiceRoutingLaunchOptions.toString()
      )['serviceOptions']
      return { sessionId: appSessionId }
    },
    listener: getDeviceTreeListener(uiObject, setUIObject)
  }
}
