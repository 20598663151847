import React from 'react'
import { CriticalUIObject, UIState } from '../interface/criticalInterface'
import { Executor } from '../executor/Executor'
import { ErrorFamily } from '../assets/error/ErrorMap'
import { getErrorFor } from '../assets/error/GetError'
import { CriticalUpdateIncomplete } from '../components/CriticalUpdateIncomplete'
import { closeServiceRoutingInstance } from './errorHandlerHelpers'

export const handleErrorCriticalIncomplete = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>,
  executor: Executor,
  identifier: string,
  isFirstTime: boolean,
  errorFamily: ErrorFamily = ErrorFamily.Internal
) => {
  const error = getErrorFor(identifier, errorFamily)
  setUIObject({
    ...uiObject,
    uiState: UIState.errorScreen,
    errorComponent: (
      <CriticalUpdateIncomplete
        isFirstTime={isFirstTime}
        onClickRetryButton={() => executor.retry()}
        onClickExitSetup={() => closeServiceRoutingInstance(executor, error)}
        errorCode={error?.errorCode}
      />
    )
  })
}
