import {
  AssetsProvider,
  JarvisAssetsProvider
} from '@jarvis/web-assets-provider'
import localeToStringsJson from './locale/index'

export class AssetsProviderFactory {
  public static create = (language: string, country: string) =>
    new JarvisAssetsProvider(
      localeToStringsJson,
      language,
      country
    ) as AssetsProvider
}
