import { DeviceType } from '../..'
import { PrinterDeviceType } from '../interface/controllerInterface'
import { HttpRequest, HttpResponse } from '../plugin/device-http-proxy'
import { deviceProxyResponse } from '../utils/deviceHttpProxyPlugin'
import {
  decodeFromBase64,
  findMultipleValuesinXml,
  findPath,
  Logger,
  parseJson
} from '../utils/helperMethods'

export const getPrinterType = async (
  deviceType: DeviceType,
  sessionId: string
): Promise<PrinterDeviceType> => {
  let options: HttpRequest
  let printerValues: string[]

  if (deviceType === DeviceType.LEDM) {
    options = {
      path: '/DevMgmt/DiscoveryTree.xml',
      sessionId
    }
    const ledmResponse = (await deviceProxyResponse(options)) as HttpResponse
    /*<ledm:SupportedTree>
  <dd:ResourceURI>/DevMgmt/ConsumableConfigDyn.xml</dd:ResourceURI>
  <dd:ResourceType>ledm:hpLedmConsumableConfigDyn</dd:ResourceType>
  <dd:Revision>http://www.hp.com/schemas/imaging/con/ledm/consumableconfigdyn/2007/11/19</dd:Revision>
</ledm:SupportedTree>
    */
    // "/ledm:DiscoveryTree/ledm:SupportedTree[./dd:ResourceType='ledm:ledm:hpLedmConsumableConfigDyn']/ledm:ResourceURI"

    Logger.log('LEDM discovery tree in printerType', ledmResponse)
    const ledmData = ledmResponse.body.isBase64Encoded
      ? decodeFromBase64(ledmResponse.body.data)
      : ledmResponse.body.data
    // parse LEDM DiscoveryTree for\ConsumableConfigDyn
    // GET ConsumableConfigDyn
    options.path = findPath(
      "/*[local-name()='DiscoveryTree']/*[local-name()='SupportedTree'][./*[local-name()='ResourceType']='ledm:hpLedmProductConfigDyn']/*[local-name()='ResourceURI']",
      ledmData
    )
    const getPrinterResponse = (await deviceProxyResponse(
      options
    )) as HttpResponse
    const printerResponseData = getPrinterResponse.body.data
    const webProtocol = 'http'
    //get the “ink”/ “inkCartridge”,/“ink-cartridge”/ “introInkSupply”/ “introInkCartridge” and printHead from the xml
    printerValues = findMultipleValuesinXml(
      '//ccdyn:ConsumableConfigDyn/ccdyn:ConsumableInfo/dd:ConsumableTypeEnum/text()', // please confirm the tree path to filter the value from the response xml
      {
        ccdyn: `${webProtocol}://www.hp.com/schemas/imaging/con/ledm/consumableconfigdyn/2007/11/19`,
        dd: `${webProtocol}://www.hp.com/schemas/imaging/con/dictionaries/1.0/`
      },
      printerResponseData
    )
    //logging the printerValues of LEDM
    Logger.log('LEDM Printer Values', printerValues)
  }

  if (deviceType === DeviceType.CDM) {
    options = {
      path: '/cdm/servicesDiscovery',
      sessionId
    }
    Logger.log('CDM system service identity in printerType', options.path)
    const discoveryResponse = (await deviceProxyResponse(
      options
    )) as HttpResponse
    Logger.log(
      '/cdm/servicesDiscovery response',
      JSON.stringify(discoveryResponse)
    )
    if (discoveryResponse.statusCode == 200) {
      const discoveryResponseData = parseJson(discoveryResponse)
      const suppliesPublicPath = discoveryResponseData.services
        .find((svc) => svc.serviceGun === 'com.hp.cdm.service.supply.version.1')
        ?.links?.find((link) => link.rel === 'suppliesPublic')?.href

      options.path = suppliesPublicPath
      if (!suppliesPublicPath) {
        Logger.log('Using fallback path for suppliesPublic')
        options.path = '/cdm/supply/v1/suppliesPublic'
      }
      Logger.log('calling suppliesPublic', options.path)
      const suppliesResponse = (await deviceProxyResponse(
        options
      )) as HttpResponse

      Logger.log('suppliesPublic response', JSON.stringify(suppliesResponse))
      if (suppliesResponse.statusCode == 200) {
        const printerResponseData = parseJson(suppliesResponse)
        //which suppliesList we need to traverse and return the supplytype value
        printerValues = printerResponseData.suppliesList.map(
          (supply) => supply.supplyType
        )
        //logging the printerValues of CDM
        Logger.log('CDM Printer Values', printerValues)
      }
    }
  }
  Logger.log('Printer Values', printerValues)
  return constructPrinterType(printerValues)
}

//find the printertype from the printerValues['ink','printerhead']
const constructPrinterType = (printerValues: string[] | null) => {
  const inkArray = filterPrinterValues(printerValues, 'ink')?.length
  const printheadArray = filterPrinterValues(printerValues, 'printhead')?.length
  if (inkArray && printheadArray) return PrinterDeviceType.IIC
  else if (inkArray) return PrinterDeviceType.IPH
  else return PrinterDeviceType.DEFAULT
}

//filter the specific string from the array
const filterPrinterValues = (
  printerValues: string[] | null,
  filterString: string
): string[] | null => {
  return printerValues?.filter((value) =>
    value.toLowerCase().includes(filterString)
  )
}
