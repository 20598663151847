import {
  EventServicePlugin,
  EventServicePluginError,
  Publisher,
  SubscribeOptions,
  Subscriber,
  SubscriptionHandle
} from '@jarvis/jweb-core'
import { Logger } from './helperMethods'

export const getPublisher = async (
  eventService: EventServicePlugin,
  publisherId: string
) => {
  Logger.log('getPublisher method called')
  const publisher = await eventService.createPublisher(publisherId)

  if (hasEventServicePluginError(publisher as EventServicePluginError))
    Logger.log(
      'getPublisher method failed with:',
      (publisher as EventServicePluginError).errorType,
      (publisher as EventServicePluginError)?.reason
    )
  else return publisher as Publisher
}

export const getSubscriber = async (
  eventService: EventServicePlugin,
  eventName: string,
  eventHandler: (data) => void,
  publisherId?: string
) => {
  Logger.log('get subscriber method is called')
  const eventSubscriber = await eventService.createSubscriber()
  let subscriptionHandler: EventServicePluginError | SubscriptionHandle

  if (!hasEventServicePluginError(eventSubscriber)) {
    subscriptionHandler = await subscribe(
      eventSubscriber as Subscriber,
      eventName,
      eventHandler,
      publisherId
    )
    if (
      hasEventServicePluginError(subscriptionHandler as EventServicePluginError)
    ) {
      Logger.log(
        'subscriptionHandler method failed with:',
        (subscriptionHandler as EventServicePluginError).errorType,
        (subscriptionHandler as EventServicePluginError)?.reason
      )
    } else {
      Logger.log('subscription handler created', subscriptionHandler)
      return subscriptionHandler
    }
  } else {
    Logger.log(
      'getSubscriber failing with:',
      (eventSubscriber as EventServicePluginError).errorType,
      (eventSubscriber as EventServicePluginError)?.reason
    )
  }
}

export const hasEventServicePluginError = (
  subscriber: Publisher | Subscriber | EventServicePluginError
) => {
  Logger.log('Event service plugin has been called with result', subscriber)
  return (subscriber as EventServicePluginError)?.errorType !== undefined
}

export const publishEvent = async (
  publisher: Publisher,
  eventName: string,
  eventData: object = {}
) => {
  Logger.log('publish method called')
  const response = await publisher.publish(eventName, eventData)
  if (
    response &&
    hasEventServicePluginError(response as EventServicePluginError)
  ) {
    Logger.log(
      `Publishing event ${eventName} failed`,
      (response as EventServicePluginError).errorType,
      (response as EventServicePluginError)?.reason
    )
    return false
  } else Logger.log(`Publishing ${eventName} Success`)
}

const subscribe = (
  subscriber: Subscriber,
  eventName: string,
  eventHandler: (data) => void,
  publisherId?: string
): Promise<SubscriptionHandle | EventServicePluginError> => {
  Logger.log('subscribe method is called')
  const subscriberOptions: SubscribeOptions = {
    eventName: eventName || null,
    publisherId: publisherId || null
  }
  Logger.log('subscribe options', subscriberOptions)
  return subscriber.subscribe(subscriberOptions, eventHandler)
}
