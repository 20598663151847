import { WhenJWebReady } from '@jarvis/jweb-core'
import { DeviceHttpProxyPlugin, HttpRequest } from '../plugin/device-http-proxy'
import { Logger } from './helperMethods'

export const deviceProxyResponse = async (
  options: HttpRequest,
  allowUserInteraction = false
) => {
  const response = await WhenJWebReady
  const DeviceHttpProxy: DeviceHttpProxyPlugin = response.Plugins
    .DeviceHttpProxy as DeviceHttpProxyPlugin
  if (!options.method) options.method = 'GET'
  if (allowUserInteraction) options.allowUserInteraction = true
  Logger.debug('DeviceHttpProxy options', options)

  return DeviceHttpProxy.sendRequest(options)
}
