import { StepConfiguration } from '../../interface/stepsInterfaces'

import { CriticalUIObject } from '../../interface/criticalInterface'
import { CriticalStepId } from '../../coordinator/criticalStepCoordinator'
import { updateProgressListener } from './listener'
import { ErrorProgressType } from '../../controller/update-progress-controller'
import { GetDeviceTypeResult } from '../getDeviceTree/action'

export const updateProgressConfiguration = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepConfiguration => {
  return {
    input: (results: Map<string, object>): object => {
      const deviceTree = results.get(
        CriticalStepId.GetDeviceTree.toString()
      ) as GetDeviceTypeResult

      const { appSessionId: sessionId } = results.get(
        CriticalStepId.GetServiceRoutingLaunchOptions.toString()
      )['serviceOptions']
      const interrogation = results.get(
        CriticalStepId.RequestCriticalUpdate.toString()
      )

      let newRetriesNumber = null

      if (results.get(CriticalStepId.UpdateProgress.toString()) == undefined) {
        newRetriesNumber = {
          command: 0,
          statusCommand: 0
        }
      } else {
        const retriesNumber = results.get(
          CriticalStepId.UpdateProgress.toString()
        )['retriesNumber']

        const response = results.get(CriticalStepId.UpdateProgress.toString())[
          'response'
        ]
        if (response.commandError.errorType == ErrorProgressType.COMMAND) {
          newRetriesNumber = {
            command: retriesNumber.command + 1,
            statusCommand: retriesNumber.statusCommand
          }
        }

        if (
          response.commandError.errorType == ErrorProgressType.STATUSCOMMAND
        ) {
          newRetriesNumber = {
            command: retriesNumber.command,
            statusCommand: retriesNumber.statusCommand + 1
          }
        }
      }

      return {
        sessionId,
        interrogation,
        retriesNumber: newRetriesNumber,
        deviceType: deviceTree.type
      }
    },
    listener: updateProgressListener(uiObject, setUIObject)
  }
}
