import { Logger } from './../../commons/utils/helperMethods'
import { CloseServiceInstanceResult } from './../interface/criticalInterface'
import { CriticalUIProps } from './../types/critical'
import { SHELL_PROPS } from './../interface/stepsInterfaces'
import { CriticalStepId } from '../coordinator/criticalStepCoordinator'
import { FwUCriticalError } from './../assets/error/ErrorMap'
import { Executor } from './../executor/Executor'

export const getSupportURL = () => 'https://www.hp.com/plus-pro-support'

export const openSupportURL = async () => {
  window.open(getSupportURL())
}

export const createSupportLink = (text: string, url: string) => {
  return replace(
    '<a onclick="window.open(\'{{url}}\')" target="_blank">{{text}}</a>',
    { text: text, url: url }
  )
}

const replace = (text: string, dict: object) => {
  for (const key in dict) {
    text = text.replace('{{' + key + '}}', dict[key])
  }
  return text
}

export const closeServiceRoutingInstance = (
  executor: Executor,
  error: FwUCriticalError
) => {
  const { onboardingContext } = executor.resultList.get(
    CriticalStepId.GetServiceRoutingLaunchOptions.toString()
  )['serviceOptions']
  const criticalUIProps = executor.resultList.get(
    SHELL_PROPS
  ) as CriticalUIProps

  const closeServiceInstancePayload = {
    resultData: {
      result: CloseServiceInstanceResult.FAILURE,
      errorInfo: {
        errorCode: `${onboardingContext.nextService?.serviceId}.${error.errorCode}`,
        errorDescription: error.description
      }
    }
  }
  Logger.log(
    'closeServiceInstancePayload',
    JSON.stringify(closeServiceInstancePayload)
  )
  criticalUIProps.serviceRouting.closeServiceInstance(
    closeServiceInstancePayload
  )
}
