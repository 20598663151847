import { StepListener } from '../../interface/stepsInterfaces'
import {
  CloseServiceInstanceResult,
  CriticalUIObject,
  StatusUpdateProgress,
  UIState
} from '../../interface/criticalInterface'
import {
  getByJSONPath,
  Logger,
  parseJson,
  sleep
} from '../../../commons/utils/helperMethods'
import { EventInfo, SubscriptionHandle } from '@jarvis/jweb-core'
import getPlugins from '../../../commons/jarvis/getPlugins'
import {
  ErrorProgressType,
  UpdateProgressEventData,
  UpdateProgressEventName,
  UpdateProgressResult,
  UpdateResult
} from '../../controller/update-progress-controller'
import React from 'react'
import { getSubscriber } from '../../../commons/utils/eventProvider'
import { handleErrorRetryFailed } from '../../utils/handleErrorRetryFailed'
import { CriticalStepId } from '../../coordinator/criticalStepCoordinator'
import { ErrorFamily } from '../../assets/error/ErrorMap'
import { handleErrorCriticalIncomplete } from '../../utils/handleErrorCriticalIncomplete'
import { ProgressBar } from './progressBar'
import { Executor } from '../../executor/Executor'
import { commonKeys } from '../../../firmwareUpdateControllerCritical/assets/error/ErrorKeyMaps'

let updateProgressEventSubscriber: SubscriptionHandle

let updateInProgress = false
const maxPercent = 95
// the increment per second is defined by dividing the maximum percentage that the progress bar
// must reach by the time it must take to complete this filling (ex. 99 / 116 = 0.85).
const incrementPerSecond = maxPercent / 600
let progressBar: ProgressBar = undefined
let isFirstTime = true

interface PayloadType {
  response: object
  retriesNumber: {
    command: number
    statusCommand: number
  }
}

export const extractFailureReason = (result: UpdateProgressResult) => {
  let failureReason = ''

  if (result && result.httpResponse) {
    const httpResponse = result.httpResponse

    const body =
      httpResponse && httpResponse.body ? parseJson(httpResponse) : undefined
    failureReason = body ? getByJSONPath('$.failureReason', body) : ''
  }

  return Array.isArray(failureReason) ? failureReason[0] : failureReason
}

export const updateProgressListener = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepListener => {
  const eventUnsubscribe = () => {
    if (updateProgressEventSubscriber) {
      updateProgressEventSubscriber.unsubscribe()
      Logger.debug('eventUnsubscribe', 'Subscribe finished')
    }
  }

  return {
    onStart: async (): Promise<void> => {
      Logger.debug('updateProgressListener.onStart')

      progressBar = new ProgressBar(
        maxPercent,
        incrementPerSecond,
        uiObject,
        setUIObject
      )

      const handleUpdateProgressEvent = async (event: EventInfo) => {
        const eventData = event.eventData as UpdateProgressEventData
        updateInProgress = eventData.updateStarted

        if (progressBar !== undefined && updateInProgress) {
          progressBar.start()
        }
      }

      updateInProgress = false
      const { EventService } = await getPlugins()

      updateProgressEventSubscriber = (await getSubscriber(
        EventService,
        UpdateProgressEventName,
        handleUpdateProgressEvent
      )) as SubscriptionHandle

      setUIObject({
        ...uiObject,
        uiState: isFirstTime
          ? UIState.loadingScreenUpdateProgressFirstTime
          : UIState.loadingScreenUpdateProgressSecondTime,
        updateProgress: {
          status: StatusUpdateProgress.NONE,
          percentProgress: 0
        }
      })

      return Promise.resolve()
    },
    onFinished: async (
      payload: PayloadType,
      executor: Executor
    ): Promise<void> => {
      Logger.debug('updateProgressListener.onFinished', payload)

      eventUnsubscribe()

      await progressBar?.stop()

      const updateProgressResult = payload.response as UpdateProgressResult

      if (updateProgressResult?.result === UpdateResult.SUCCESS) {
        progressBar?.completeProgress()
        await sleep(2000)
        setUIObject({
          ...uiObject,
          uiState: UIState.UpdateProgressScreen,
          updateProgress: {
            status: StatusUpdateProgress.FINISHED
          }
        })

        const lauchServiceOptions = executor.resultList.get(
          CriticalStepId.GetServiceRoutingLaunchOptions.toString()
        )
        const CloseServiceInstancePayload = {
          resultData: {
            result: CloseServiceInstanceResult.SUCCESS
          }
        }
        executor.updateStepResult(
          {
            lauchServiceOptions,
            CloseServiceInstancePayload
          },
          CriticalStepId.GetServiceRoutingLaunchOptions.toString()
        )
        progressBar = undefined
        return Promise.resolve()
      } else {
        isFirstTime = false
        if (updateProgressResult.commandError.status === true) {
          if (
            updateProgressResult.commandError.errorType ===
              ErrorProgressType.COMMAND &&
            payload.retriesNumber.command == 0
          ) {
            setUIObject({
              ...uiObject,
              uiState: UIState.mitigationNoticeScreen
            })
          } else if (
            updateProgressResult.commandError.errorType ===
              ErrorProgressType.STATUSCOMMAND &&
            payload.retriesNumber.statusCommand == 0
          ) {
            const failureReason = extractFailureReason(updateProgressResult)
            handleErrorCriticalIncomplete(
              uiObject,
              setUIObject,
              executor,
              failureReason,
              true,
              ErrorFamily.AutoUpdate
            )
          }

          if (
            payload.retriesNumber.command > 0 &&
            updateProgressResult.commandError.errorType ===
              ErrorProgressType.COMMAND
          ) {
            const failureReason = extractFailureReason(updateProgressResult)
            handleErrorRetryFailed(
              uiObject,
              setUIObject,
              executor,
              failureReason,
              ErrorFamily.AutoUpdate
            )
          } else if (
            payload.retriesNumber.statusCommand > 0 &&
            updateProgressResult.commandError.errorType ===
              ErrorProgressType.STATUSCOMMAND
          ) {
            const failureReason = extractFailureReason(updateProgressResult)
            handleErrorCriticalIncomplete(
              uiObject,
              setUIObject,
              executor,
              failureReason,
              false,
              ErrorFamily.AutoUpdate
            )
          }
          progressBar = undefined
          return Promise.resolve()
        }

        if (updateProgressResult.commandError.status === false) {
          Logger.debug(
            'Could not process firmware update from printer!',
            updateProgressResult?.errorMessage
          )
          handleErrorRetryFailed(
            uiObject,
            setUIObject,
            executor,
            commonKeys.values.unknownValueKey,
            ErrorFamily.AutoUpdate
          )
          progressBar = undefined
          return Promise.resolve()
        }
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onError: (exception: Error, executor: Executor): Promise<void> => {
      Logger.debug('updateProgressListener.onError', exception)
      eventUnsubscribe()
      progressBar?.stop()
      progressBar = undefined
      return Promise.resolve()
    }
  }
}
