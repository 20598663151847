import React from 'react'
import { CriticalUIObject, UIState } from '../interface/criticalInterface'
import { ModalError } from '../components/ModalError'
import { ErrorFamily } from '../assets/error/ErrorMap'
import { getErrorFor } from '../assets/error/GetError'
import { Logger } from '../../commons/utils/helperMethods'
import { Executor } from '../executor/Executor'
import { closeServiceRoutingInstance } from './errorHandlerHelpers'

export const handleError = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>,
  exception: Error,
  executor: Executor,
  identifier: string,
  errorFamily: ErrorFamily = ErrorFamily.Internal
) => {
  Logger.log(exception.message)
  const error = getErrorFor(identifier, errorFamily)
  Logger.log('HandleError calling with: ', error.errorCode, error.description)

  setUIObject({
    ...uiObject,
    uiState: UIState.errorScreen,
    errorComponent: (
      <ModalError
        onClickRetryButton={() => executor.retry()}
        onClickExitSetup={() => closeServiceRoutingInstance(executor, error)}
        errorCode={error?.errorCode}
      />
    )
  })
}
