/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import { IconMinusCircle, Button } from '@veneer/core'

import {
  Container,
  Title,
  InformationContainer,
  ButtonsContainer,
  BodyContainer
} from './styles'
import { useAssetsProvider } from '../../context/Language'
import {
  createSupportLink,
  getSupportURL
} from '../../utils/errorHandlerHelpers'
import {
  createSimpleUiEvent,
  CustomDataSimpleUiEvent,
  sendSimpleUiEvent
} from '../../../firmwareUpdateControllerCritical/jarvis/dataCollection'
import { Action } from '@jarvis/jweb-core'

interface ModalErrorProps {
  onClickRetryButton: () => void
  onClickExitSetup: () => void
  errorCode?: string
}

// Simpleui event parameters
const screenName = 'ModalError'

const openScreen: CustomDataSimpleUiEvent = {
  action: Action?.screenDisplayed,
  screenName: screenName
}

const exitButton: CustomDataSimpleUiEvent = {
  action: Action?.controlButtonClicked,
  screenName: screenName,
  controlName: 'ExitSetupButton'
}

const retryButton: CustomDataSimpleUiEvent = {
  action: Action?.controlButtonClicked,
  screenName: screenName,
  controlName: 'RetrySetupButton'
}

const supportLink: CustomDataSimpleUiEvent = {
  action: Action?.controlHyperLinkClicked,
  screenName: screenName,
  controlName: 'GoToHpSupportWebsite',
  screenMode: 'UpdateError'
}

export const ModalError: React.FC<ModalErrorProps> = ({
  onClickRetryButton,
  onClickExitSetup,
  errorCode
}) => {
  const informationContainerTwoRef = useRef<HTMLParagraphElement>()

  const assetsProvider = useAssetsProvider()

  const hpSupportLink = createSupportLink(
    assetsProvider.getText('modal_error.hp_support_link_label'),
    getSupportURL()
  )
  const informationContainerOne = assetsProvider.getText(
    'modal_error.information_container_one'
  )

  const informationContainerTwo = assetsProvider.getText(
    'modal_error.information_container_two',
    { hpSupportLink }
  )

  useEffect(() => {
    sendSimpleUiEvent(createSimpleUiEvent(openScreen))
    informationContainerTwoRef.current.innerHTML = informationContainerTwo
  }, [])

  const handleRetryButton = () => {
    onClickRetryButton()
    sendSimpleUiEvent(createSimpleUiEvent(retryButton))
  }

  const handleExitButton = () => {
    onClickExitSetup()
    sendSimpleUiEvent(createSimpleUiEvent(exitButton))
  }

  const handlerSupportLink = (e) => {
    const el = e.target

    if (el && el.tagName === 'A') {
      sendSimpleUiEvent(createSimpleUiEvent(supportLink))
    }
  }

  return (
    <Container>
      <BodyContainer>
        <IconMinusCircle id="icon" color="colorRed6" />
        <Title>{assetsProvider.getText('modal_error.title')}</Title>
        <InformationContainer>
          <p className="body">{informationContainerOne}</p>
          <p
            onClick={handlerSupportLink}
            className="body"
            ref={informationContainerTwoRef}
          />
          {errorCode ? <p className="errorCode">({errorCode})</p> : null}
        </InformationContainer>
      </BodyContainer>

      <ButtonsContainer>
        <Button appearance="secondary" onClick={() => handleExitButton()}>
          {assetsProvider.getText('commons.exit_setup')}
        </Button>
        <Button onClick={() => handleRetryButton()}>
          {assetsProvider.getText('commons.retry')}
        </Button>
      </ButtonsContainer>
    </Container>
  )
}
