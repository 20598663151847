import React from 'react'
import { StepListener } from '../../interface/stepsInterfaces'
import {
  CloseServiceInstanceResult,
  CriticalUIObject
} from '../../interface/criticalInterface'
import { CriticalStepId } from '../../coordinator/criticalStepCoordinator'
import { Executor } from '../../executor/Executor'
import { handleError } from '../../../firmwareUpdateControllerCritical/utils/handleError'
import { ErrorFamily } from '../../../firmwareUpdateControllerCritical/assets/error/ErrorMap'
import { Logger } from '../../../commons/utils/helperMethods'

export const noUpdateRequiredListener = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepListener => {
  return {
    onStart: (): Promise<void> => {
      Logger.debug('noUpdateRequiredListener.OnStart')
      return Promise.resolve()
    },
    onFinished: async (payload: object, executor: Executor): Promise<void> => {
      Logger.debug('noUpdateRequiredListener.OnFinished', payload)
      const lauchServiceOptions = executor.resultList.get(
        CriticalStepId.GetServiceRoutingLaunchOptions.toString()
      )
      const CloseServiceInstancePayload = {
        resultData: {
          result: CloseServiceInstanceResult.SUCCESS
        }
      }

      executor.updateStepResult(
        {
          lauchServiceOptions,
          CloseServiceInstancePayload
        },
        CriticalStepId.GetServiceRoutingLaunchOptions.toString()
      )
      executor.executeNextStep()
      return Promise.resolve()
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onError: (exception: Error, executor: Executor): Promise<void> => {
      Logger.debug('noUpdateRequiredListener.OnError', exception)
      handleError(
        uiObject,
        setUIObject,
        exception,
        executor,
        exception.message,
        ErrorFamily.Internal
      )
      return Promise.resolve()
    }
  }
}
