import { StepConfiguration } from '../../interface/stepsInterfaces'
import { CriticalStepId } from '../../coordinator/criticalStepCoordinator'
import { CriticalUIObject } from '../../interface/criticalInterface'
import { networkConnectivityCheckListener } from './listener'

export const networkConnectivityCheckStepConfiguration = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepConfiguration => {
  return {
    input: (results: Map<string, object>): object => {
      const { appSessionId } = results.get(
        CriticalStepId.GetServiceRoutingLaunchOptions.toString()
      )['serviceOptions']

      if (
        results.get(CriticalStepId.NetworkConnectivityCheck.toString()) ==
        undefined
      ) {
        return { sessionId: appSessionId, isPatchRequest: true }
      } else {
        return {
          sessionId: appSessionId,
          isPatchRequest: results.get(
            CriticalStepId.NetworkConnectivityCheck.toString()
          )['isPatchRequest']
        }
      }
    },
    listener: networkConnectivityCheckListener(uiObject, setUIObject)
  }
}
