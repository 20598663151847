import { autoUpMapKeys, commonKeys } from './ErrorKeyMaps'
import { FwUCriticalError } from './ErrorMap'

export const autoUpMap: Map<string, FwUCriticalError> = new Map([
  [
    autoUpMapKeys.values.versionUpToDate,
    {
      description:
        'Current version was determined to be the latest version available.',
      errorCode: 'EFW02P0010'
    }
  ],
  [
    autoUpMapKeys.values.downloadFailed,
    {
      description: 'Download from push or pull failed.',
      errorCode: 'EFW02U0007'
    }
  ],
  [
    autoUpMapKeys.values.remoteUrlNotReachable,
    {
      description: 'Connection failed',
      errorCode: 'EFW02A0008'
    }
  ],
  [
    autoUpMapKeys.values.connectionTimeout,
    {
      description: 'Connection successful, but response timed out.',
      errorCode: 'EFW02A0009'
    }
  ],
  [
    autoUpMapKeys.values.diskFull,
    {
      description: 'Note enough disk space to store the download.',
      errorCode: 'EFW02U0011'
    }
  ],
  [
    autoUpMapKeys.values.updateSuperseded,
    {
      description:
        'Image downloaded and paused in the "installReady" state and a new operation is started, the current operation will be terminated and report "updateSuperseded" as the failureResult.',
      errorCode: 'EFW02A0001'
    }
  ],
  [
    autoUpMapKeys.values.systemCancelled,
    {
      description:
        'Image downloaded and paused in the "installReady" state and device rebooted  or the system cancels for any reason, results in a a failed and systemCancelled',
      errorCode: 'EFW02A0002'
    }
  ],
  [
    autoUpMapKeys.values.systemInstallFailed,
    {
      description:
        'Main firmware installation step (after download) failed.  System will attempt to restart back to the current firmware.   Name change from "updateFailed".',
      errorCode: 'EFW02A0012'
    }
  ],
  [
    autoUpMapKeys.values.subsytemInstallFailed,
    {
      description:
        'A less-critical subsystem/component install failed, system will continue with the failure.  Think of this as a partial update.  The functionality associated with the component may be impacted.  Restart would re-run the component installer and may succeed.',
      errorCode: 'EFW02A0013'
    }
  ],
  [
    autoUpMapKeys.values.downgradeNotAllowed,
    {
      description: 'Device configuration profile does not allow downgrades',
      errorCode: 'EFW02U0014'
    }
  ],
  [
    autoUpMapKeys.values.invalidBundle,
    {
      description:
        'Incompatible, signature failure, CRC mismatch, invalid package',
      errorCode: 'EFW02P0006'
    }
  ],
  [
    autoUpMapKeys.values.invalidManifest,
    {
      description:
        'Manifest description or de-serialization error or invalid value(s)',
      errorCode: 'EFW02A0003'
    }
  ],
  [
    autoUpMapKeys.values.manifestNotFound,
    {
      description:
        'Latest firmware should always be there, even if initial shipping version.',
      errorCode: 'EFW02A0015'
    }
  ],
  [
    autoUpMapKeys.values.protocolError,
    {
      description: '4xx or 5xx response from firmware server',
      errorCode: 'EFW02A0004'
    }
  ],
  [
    autoUpMapKeys.values.internalError,
    {
      description: 'Unknown uncaught exception',
      errorCode: 'EFW02A0005'
    }
  ],
  [
    commonKeys.values.unknownValueKey,
    {
      description: 'Unknown error during printer update',
      errorCode: 'EFW02A0000'
    }
  ],
  [
    autoUpMapKeys.values.invalidEnvironment,
    {
      description: 'Invalid environment. JWeb plugins not available',
      errorCode: 'EFW02A0016'
    }
  ]
])
