export const autoUpMapKeys = {
  values: {
    versionUpToDate: 'versionUpToDate',
    downloadFailed: 'downloadFailed',
    remoteUrlNotReachable: 'remoteUrlNotReachable',
    connectionTimeout: 'connectionTimeout',
    diskFull: 'diskFull',
    updateSuperseded: 'updateSuperseded',
    systemCancelled: 'systemCancelled',
    systemInstallFailed: 'systemInstallFailed',
    subsytemInstallFailed: 'subsytemInstallFailed',
    downgradeNotAllowed: 'downgradeNotAllowed',
    invalidBundle: 'invalidBundle',
    invalidManifest: 'invalidManifest',
    manifestNotFound: 'manifestNotFound',
    protocolError: 'protocolError',
    internalError: 'internalError',
    invalidEnvironment: 'invalidEnvironment'
  }
}

export const backendMapKeys = {
  values: {
    _500: '500',
    _400: '400',
    _401: '401',
    _403: '403'
  }
}

export const internalMapKeys = {
  values: {
    couldNotGetLaunchOptions: 'couldNotGetLaunchOptions',
    couldNotGetDeviceIdFromPrinter: 'couldNotGetDeviceIdFromPrinter',
    invalidConnectionForBusinessModel: 'invalidConnectionForBusinessModel'
  }
}

export const intDiagMapKeys = {
  values: {
    proxyNotConfigured: 'proxyNotConfigured',
    httpRedirect: 'httpRedirect',
    other: 'other',
    networkNotConnected: 'networkNotConnected',
    duplicateIp: 'duplicateIp',
    proxyConnectionFailed: 'proxyConnectionFailed',
    proxyURLResolutionFailed: 'proxyURLResolutionFailed',
    proxyRequiresAuthentication: 'proxyRequiresAuthentication',
    proxyCredentialsIncorrect: 'proxyCredentialsIncorrect',
    ipNotRoutable: 'ipNotRoutable',
    dnsNotConfigured: 'dnsNotConfigured',
    dnsNotResolved: 'dnsNotResolved',
    dnsNotReachable: 'dnsNotReachable',
    gatewayNotConfigured: 'gatewayNotConfigured',
    gatewayNotReachable: 'gatewayNotReachable'
  }
}

export const commonKeys = {
  values: {
    unknownValueKey: 'unknownValueKey'
  }
}
