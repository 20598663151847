import React from 'react'
import { StepListener } from '../../interface/stepsInterfaces'
import { CriticalUIObject, UIState } from '../../interface/criticalInterface'
import { HttpResponse } from '../../../commons/plugin/device-http-proxy'
import { HttpMethodType } from '../../../commons/interface/controllerInterface'
import { handleError } from '../../utils/handleError'
import { ErrorFamily } from '../../assets/error/ErrorMap'
import { Executor } from '../../executor/Executor'
import { Logger, parseJson } from '../../../commons/utils/helperMethods'

interface PayloadType {
  response: object
  isPatchRequest: boolean
}

export const networkConnectivityCheckListener = (
  uiObject: CriticalUIObject,
  setUIObject: React.Dispatch<React.SetStateAction<CriticalUIObject>>
): StepListener => {
  return {
    onStart: (): Promise<void> => {
      Logger.debug('networkConnectivityCheckListener.OnStart')
      setUIObject({ ...uiObject, uiState: UIState.startCriticalUpdateScreen })
      return Promise.resolve()
    },
    onFinished: (payload: PayloadType, executor: Executor): Promise<void> => {
      Logger.debug('networkConnectivityCheckListener.OnFinished', payload)
      const timePulling = 5000
      const checkResponse = payload.response as HttpResponse
      if (checkResponse.statusCode == 200 || checkResponse.statusCode == 202) {
        if (checkResponse.method == HttpMethodType.PATCH) {
          setTimeout(() => {
            executor.rerunAction()
          }, timePulling)
        } else {
          const data = parseJson(checkResponse)
          if (data.state == 'processing') {
            setTimeout(() => {
              executor.rerunAction()
            }, timePulling)
          } else if (data.state == 'ready' && data.lastResult == 'connected') {
            executor.executeNextStep()
          } else if (data.state == 'ready' && data.lastResult == 'failed') {
            handleError(
              uiObject,
              setUIObject,
              new Error(`The reason for the failure is: ${data.failureReason}`),
              executor,
              data.failureReason,
              ErrorFamily.InternetDiagnostics
            )
          }
        }
      } else {
        handleError(
          uiObject,
          setUIObject,
          new Error(`statusCode: ${checkResponse.statusCode}`),
          executor,
          `${checkResponse.statusCode}`,
          ErrorFamily.InternetDiagnostics
        )
      }
      return Promise.resolve()
    },
    onError: (exception: Error, executor: Executor): Promise<void> => {
      Logger.debug('networkConnectivityCheckListener.OnError', exception)
      handleError(
        uiObject,
        setUIObject,
        exception,
        executor,
        exception.message,
        ErrorFamily.InternetDiagnostics
      )
      return Promise.resolve()
    }
  }
}
