import {
  DeviceType,
  UpdateFilter
} from '../../../commons/clients/stageapi/firmware-update-client'
import { oobeUpdateAvailabilityResponse } from '../../../commons/utils/api'
import Action from '../../interface/action'

interface InterrogationInput {
  baseUrl: string
  jarvisAuthProvider: object
  deviceId: object
  type: DeviceType
  filter: UpdateFilter
}

const callInterrogationApi = (input: InterrogationInput) => {
  return oobeUpdateAvailabilityResponse(
    input.baseUrl,
    input.deviceId,
    input.type
  ) // calling the interrogation api
}

export class InterrogationAction extends Action {
  execute(input: InterrogationInput): Promise<object> {
    return callInterrogationApi(input)
  }
}
